import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Material } from '@domain/models/material.model';
import { MaterialGroup } from '@domain/models/material-group.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss'],
})
export class MaterialComponent implements OnInit, OnDestroy {
  public project = new Project({});
  public materials: Material[] = [];
  public materialGroups: MaterialGroup[] = [];
  public projectMaterials: ProjectMaterial[] = [];
  public disabled = true;
  public arentMaterialsEnabled = false;

  private subscriptionProjectLoaded: Subscription;

  public constructor(private projectService: ProjectService,
                     private synchronisationService: SynchronisationService) {
    /** ToDo: Change when refactored to settings */
    this.arentMaterialsEnabled = this.synchronisationService.hasTenantARentModule();

    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });
  }

  public async ngOnInit(): Promise<void> {
    if (!this.arentMaterialsEnabled) {
      await this.getMaterials();
    }

    // Load on project
    this.subscriptionProjectLoaded = this.projectService.getCurrentProject().subscribe(async (project: Project) => {
      this.project = project;
      await this.getProjectMaterials();
    });
  }

  public async getProjectMaterials(): Promise<void> {
    if (this.arentMaterialsEnabled) {
      this.materialGroups = await MaterialGroup.query.toArray();
    }

    this.projectMaterials = await ProjectMaterial.query
        .where('project_id')
        .equals(this.project.id)
        .toArray();
  }

  public async onMaterialGroupChange(materialGroup: MaterialGroup): Promise<void> {
    await this.getMaterials(materialGroup);
  }

  public async ngOnDestroy(): Promise<void> {
    await this.projectService.saveProjectMaterials(this.projectMaterials);
    this.projectService.setProjectUpdated();
    await this.projectService.saveProject(this.project);

    await this.project.loadMaterials();
    this.projectService.setCurrentProject(this.project);

    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  public getAmount(material: Material): number {
    const item = this.getMaterialItem(material);
    return item ? item.amount : 0;
  }

  public addAmountGlobal(material: Material): void {
    if (!this.disabled) {
      this.addAmount(material);
    }
  }

  public removeAmountGlobal(material: Material): void {
    if (!this.disabled) {
      this.removeAmount(material);
    }
  }

  public changeAmount(amount: number, material: Material): void {
    this.addAmount(material, amount);
  }

  public addAmount(material: Material, amount?: number): void {
    const projectMaterial = this.getMaterialItem(material);
    let value = (!amount || amount < 0) ? 1 : +amount;

    if (typeof value !== 'number') {
      value = 1;
    }

    if (projectMaterial) {
      !amount ? (projectMaterial.amount += +value) : projectMaterial.amount = +value;

      this.projectService.updateMaterial(projectMaterial);
    } else {
      const newProjectMaterial = new ProjectMaterial({
        project_id: this.project.id,
        material_id: material.id,
        amount: value,
      });

      this.projectMaterials.push(newProjectMaterial);
      this.projectService.updateMaterial(newProjectMaterial);
    }
  }

  private removeAmount(material: Material): void {
    const item = this.getMaterialItem(material);
    const amount = 1;

    if (item && item.amount > 0) {
      item.amount -= amount;

      this.projectService.updateMaterial(item);
    }
  }

  private async getMaterials(materialGroup?: MaterialGroup): Promise<void> {
    let result;
    if (materialGroup) {
      result = await Material.query
          .where('material_group_id')
          .equals(materialGroup.id)
          .toArray();
    } else {
      result = await Material.query.toArray();
    }

    // Sort the materials alphabetically
    this.materials = result.sort((a: any, b: any) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();

      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  private getMaterialItem(material: Material): ProjectMaterial {
    return this.projectMaterials.find((value: ProjectMaterial) => {
      return value.material_id === material.id;
    });
  }
}
