import { DomainModel } from '@domain/domain.model';
import * as uuid from 'uuid/v4';
import { AddressField } from '@domain/models/address-field.model';

export class AddressFieldValue extends DomainModel {
  // Configuration
  public entity = 'address_field_value';
  public table = 'address_field_values';
  public schema = 'id, address_id';
  public sync = true;
  public id: string;

  // Fields
  public address_id: string;
  public address_field_id: string;
  public value: string | boolean;
  public address_field: AddressField;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public getData(): any {
    return {
      id: this.id,
      address_id: this.address_id || '',
      address_field_id: this.address_field_id || '',
      value: this.value || '',
    };
  }
}
