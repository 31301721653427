import { TenantConfigQuotation } from '@domain/models/tenant-config-quotation.model';
import { TenantConfigStylesheet } from '@domain/models/tenant-config-stylesheet.model';
import { Setting } from '@domain/models/setting.model';
import { DomainModel } from '@domain/domain.model';

export class Tenant extends DomainModel {
  public entity = 'tenant';
  public table = 'tenant';
  public id: string;
  public name: string;
  public code: string;
  public host: string;
  public api_planning_module: boolean;
  public api_exact_online: boolean;
  public api_boelaars_en_lambert: boolean;
  public api_arent: boolean;
  public config_stylesheet: TenantConfigStylesheet;
  public config_quotation: TenantConfigQuotation;
  public settings: Setting[] | any;

  constructor(attributes) {
    super(attributes);

    for (const attributeName in attributes) {
      if (attributes.hasOwnProperty(attributeName)) {
        this[attributeName] = attributes[attributeName];
      }
    }
  }
}
