import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from '@node_modules/rxjs';
import { DataService } from '@shared/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ConfirmationService } from '@node_modules/primeng/primeng';
import { takeUntil } from '@node_modules/rxjs/operators';
import * as toastr from 'toastr';
import { QuotationCheckService } from '@features/manage/quotation-checks/services/quotation-check.service';
import { QuotationCheck } from '@domain/models/quotation-check.model';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-quotation-check-detail',
  templateUrl: './quotation-check-detail.component.html',
  styleUrls: ['./quotation-check-detail.component.scss']
})
export class QuotationCheckDetailComponent implements OnInit, OnDestroy {
  public item: QuotationCheck;
  public isAdd = false;
  public loading = false;
  public showErrors = false;
  public form: FormGroup;
  public mode = { isAdd: true };
  public projectTypeList: SelectItem[] = [
    {
      label: 'Zakelijk',
      value: 'business',
    },
    {
      label: 'Particulier',
      value: 'private'
    }
  ];
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
              private dataService: DataService,
              private quotationCheckService: QuotationCheckService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private synchronisationService: SynchronisationService,
              private confirmationService: ConfirmationService,
              private route: ActivatedRoute) {

  }

  public ngOnInit(): void {
    // Get id of url to edit by route params
    const params = this.route.snapshot.params;
    const id = params['id'];
    this.isAdd = id === 'add';

    this.initForm();

    if (!this.isAdd) {
      this.quotationCheckService.get(id).pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.item = result;
        }

        this.form.patchValue(result);

        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSubmit(): void {
    if (this.form.valid) {
      const itemData = this.form.getRawValue();
      this.showErrors = false;
      this.loading = true;

      let request;
      if (this.isAdd) {
        request = this.quotationCheckService.post(itemData);
      } else {
        request = this.quotationCheckService.patch(this.route.snapshot.params['id'], itemData);
      }

      request.pipe(takeUntil(this.destroy$)).subscribe(_ => {
        toastr.success('Offerte check succesvol opgeslagen', 'Offerte check');
        this.router.navigateByUrl('/').then(() => {
          // HACK Use double navigation to force reload..
          this.router.navigateByUrl('/admin/manage/quotation-checks');
        });
      }, (error: any) => {
        if (error.status === 422 && error.json) {
          toastr.warning('Ongeldige invoer', 'Offerte check');
        } else {
          toastr.error('Fout bij opslaan offerte check', 'Offerte check');
        }

        this.loading = false;
      });
    } else {
      this.showErrors = true;
    }
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/manage/quotation-checks');
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      label: ['', [Validators.required]],
      index: [''],
      project_type: ['', Validators.required],
    });
  }
}
