import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from '@node_modules/rxjs';
import { DexieStore } from '@domain/dexie-store';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router/src/router_state';

@Injectable()
export class BootstrapGuard implements CanActivate {
  constructor(private router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((observer) => {
      const store = DexieStore.getInstance();

      if (!store) {
        this.navigateToLogin(state.url);
        observer.next(false);
        observer.complete();
      }

      store.initialize().then(() => {
        observer.next(true);
        observer.complete();
      }).catch((error) => {
        this.navigateToLogin(state.url);
        observer.error(error);
        observer.complete();
      });
    });
  }

  private navigateToLogin(route: string): void {
    this.router.navigate(['/failure'], {queryParams: {redirectUrl: route}});
  }
}
