import { AbstractControl } from '@angular/forms';

export function ValidateAlphaNumeric(control: AbstractControl) {
  const alphaNumeric = new RegExp('^\\w+(?!\\d)+[\\w\\s-.]+?[^ ]+$');

  return alphaNumeric.test(control.value) ? null : {
    ValidateAlphaNumeric: {
      valid: false
    }
  };
}
