import { DomainModel } from '@domain/domain.model';

export class DefaultItem extends DomainModel {

  // Configuration
  public entity = 'default-item';
  public table = 'default_items';
  public sync = true;

  // Fields
  public name: string;
  public volume: string;
  public meterbox: number;
  public remarks: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
