import { DomainModel } from '@domain/domain.model';
import * as uuid from 'uuid/v4';

export class ContactTemplate extends DomainModel {
  // Configuration
  public entity = 'contact_template';
  public table = 'contact_templates';
  public sync = true;
  public id: string;

  // Fields
  public company: string;
  public name: string;
  public initials?: string;
  public gender?: string;
  public email: string;
  public phone: string;
  public mobile?: string;
  public remarks?: string;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  /**
   * Return a display friendly name
   */
  public getDisplayName(): string {
    return `${this.name || ''} ${this.email || ''}${this.phone || ''},
        ${this.mobile || ''}, ${this.remarks || ''}`;
  }

  public getData(): any {
    return {
      id: this.id || '',
      company: this.company || '',
      name: this.name || '',
      initials: this.initials || '',
      gender: this.gender || '',
      email: this.email || '',
      phone: this.phone || '',
      mobile: this.mobile || '',
      remarks: this.remarks || ''
    };
  }
}
