import { Injectable } from '@angular/core';
import { Observable } from '@root/node_modules/rxjs';
import { Http } from '@angular/http';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { AuthService } from '@blueprint/auth/auth.service';

@Injectable()
export class QuotationSignService extends ApiServiceWithLoaderService {
  public endpoint = 'quotation/sign';

  constructor(http: Http,
              authService: AuthService) {
    super(http, authService);
  }

  public async validateQuotation(data: any): Promise<boolean> {
    const result = await this.postValidateQuotation(data).toPromise();

    return true;
  }

  public getQuotation(quotationSignId: string, projectId: string): Observable<any> {
    return this.get(`/${this.endpoint}/${quotationSignId}/${projectId}`);
  }

  public signQuotation(data: { quotation_sign_id: string, quotation: any }): Observable<any> {
    return this.post(`/${this.endpoint}`, data);
  }

  public postValidateQuotation(data: any): Observable<any> {
    return this.post(`/verify/${this.endpoint}`, [data]);
  }
}
