import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap';
import { CheckboxModule, DataTableModule, MultiSelectModule, TooltipModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';
import { OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { OnlineTableComponent } from '@shared/controls/table/online-table.component';
import { AsteriksComponent } from '@shared/controls/asteriks/asteriks.component';
import { AppPatternRestrictDirective } from '@shared/directives/app-pattern-restrict.directive';
import { SortByDatePropertyPipe } from '@shared/pipes/sort-by-date-property.pipe';
import { SortByIndexPipe } from '@shared/pipes/sort-by-index.pipe';
import { AddMyTenantPipe } from '@shared/pipes/add-my-tenant.pipe';
import { SignatureComponent } from '@shared/controls/signature/signature.component';
import { SignaturePadModule } from '@node_modules/angular2-signaturepad';
import { ConvertVatPrice } from '@shared/pipes/convert-vat-price.pipe';
import { SortByPropertyPipe } from '@shared/pipes/sort-by-property.pipe';
import { Nl2BrPipe } from '@shared/pipes/nl2br.pipe';
import { SettingPipe } from './pipes/setting.pipe';
import { ObservablePipe } from './pipes/observable.pipe';
import { NestedColumnValuePipe } from './pipes/nested-column-value.pipe';
import { ContactFullNamePipe } from '@shared/pipes/contact-full-name.pipe';
import { CommonModule } from '@angular/common';
import { QuotationChecksComponent } from './components/quotation-checks/quotation-checks.component';

@NgModule({
  declarations: [
    OfflineTableComponent,
    OnlineTableComponent,
    AppPatternRestrictDirective,
    AsteriksComponent,
    SortByDatePropertyPipe,
    SortByIndexPipe,
    SortByPropertyPipe,
    AddMyTenantPipe,
    ConvertVatPrice,
    ContactFullNamePipe,
    SignatureComponent,
    Nl2BrPipe,
    SettingPipe,
    ObservablePipe,
    NestedColumnValuePipe,
    QuotationChecksComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    EmendisBlueprintModule,
    PaginationModule.forRoot(),
    DataTableModule,
    TableModule,
    TooltipModule,
    MultiSelectModule,
    SignaturePadModule,
    ReactiveFormsModule,
    CheckboxModule,
  ],
  exports: [
    OfflineTableComponent,
    OnlineTableComponent,
    AsteriksComponent,
    AppPatternRestrictDirective,
    SortByDatePropertyPipe,
    SortByIndexPipe,
    SortByPropertyPipe,
    ConvertVatPrice,
    ContactFullNamePipe,
    AddMyTenantPipe,
    PaginationModule,
    MultiSelectModule,
    SignatureComponent,
    Nl2BrPipe,
    SettingPipe,
    QuotationChecksComponent,
  ]
})

export class SharedModule {
}
