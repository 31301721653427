import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { InventoryClientComponent } from '@features/inventory/client/client.component';
import { InventoryAddressListComponent } from '@features/inventory/address/address-list.component';
import { InventoryOptionsDetailComponent } from '@features/inventory/options/options-detail.component';


@Injectable()
export class OptionsGuard implements CanDeactivate<InventoryOptionsDetailComponent> {
  private home = '/admin/projects';
  private popup = 'popup';

  canDeactivate(
    component: InventoryOptionsDetailComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState?: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) {

    return true;
  }
}
