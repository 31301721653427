import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { FormBuilder } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { Address } from '@domain/models/address.model';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import { takeUntil } from '@node_modules/rxjs/operators';

@Component({
  selector: 'app-inventory-options-detail',
  templateUrl: 'options-detail.component.html'
})
export class InventoryOptionsDetailComponent implements OnInit, OnDestroy {
  public form: any;
  public project = new Project({});
  public activities: ProjectActivity[] = [];
  public specialties: ProjectSpecialty[] = [];
  public disabled = true;
  public addresses: Address[] = [];
  public addressOptions: { label: string, value: string }[] = [];
  public displayActivityArray: any = [];
  public value: Date;
  public numberTrucks: SelectItem[];
  public numberMovers: SelectItem[];
  public localeNL: CalendarLocale = new CalendarLocale();

  private subscriptionProjectLoaded: Subscription;
  private destroy$: Subject<void> = new Subject<void>();

  public constructor(private projectService: ProjectService,
                     private formBuilder: FormBuilder) {
    this.projectService.projectIsReadOnly.pipe(takeUntil(this.destroy$)).subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });

    this.numberMovers = [];
    this.numberMovers.push({ label: '1', value: '1' });
    this.numberMovers.push({ label: '2', value: '2' });
    this.numberMovers.push({ label: '3', value: '3' });
    this.numberMovers.push({ label: '4', value: '4' });
    this.numberMovers.push({ label: '5', value: '5' });
    this.numberMovers.push({ label: '8', value: '8' });
    this.numberMovers.push({ label: '10', value: '10' });
    this.numberMovers.push({ label: '15', value: '15' });
    this.numberMovers.push({ label: '20', value: '20' });
    this.numberMovers.push({ label: '25', value: '25' });
    this.numberMovers.push({ label: '50', value: '50' });

    this.numberTrucks = this.numberMovers;
    this.displayActivityArray = [];
  }

  public async ngOnInit(): Promise<void> {
    // Reload when project changes
    this.subscriptionProjectLoaded = this.projectService.getCurrentProject().subscribe(async (project: Project) => {
      this.project = project;
      await this.project.loadActivities();
      this.addresses = project.addresses;
      this.specialties = project.specialties;
      this.activities = this.project.activities;

      this.projectService.setCurrentClient(this.project.client);
      this.updateActivities();
      this.initForm();
      await this.initAddresses();
    });

    this.initForm();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      own_description_activities: this.formBuilder.control(this.project.own_description_activities || ''),
      client_description_activities: this.formBuilder.control(this.project.client_description_activities || ''),
      particularities_damage_remarks: this.formBuilder.control(this.project.particularities_damage_remarks || ''),
      insurance_certificate_link: this.formBuilder.control(this.project.insurance_certificate_link || ''),
      delivery_date: this.formBuilder.control(this.project.delivery_date || ''),
    });
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    this.project.own_description_activities = this.form.value.own_description_activities;
    this.project.client_description_activities = this.form.value.client_description_activities;
    this.project.particularities_damage_remarks = this.form.value.particularities_damage_remarks;
    this.project.delivery_date = this.form.value.delivery_date;

    // ToDo: Only save used project specialties
    // this.specialties = this.specialties.filter(specialty => specialty.applicable);

    await this.projectService.saveActivities(this.activities);
    await this.projectService.saveSpecialties(this.specialties);

    this.projectService.setProjectUpdated();
    await this.projectService.saveProject(this.project);

    this.destroy$.next();
    this.destroy$.complete();
  }

  public toggleSwitch(projectSpecialtyId: string): void {
    this.specialties.find((item) => {
      if (item.id === projectSpecialtyId) {
        if (item.applicable) {
          item.applicable = null;
          item.hours_estimate = null;
        } else {
          item.applicable = true;
        }

        return true;
      }
    });
  }

  public setApplicable(activities: any): void {
    activities.forEach((activityItem: ProjectActivity) => {
      activityItem.applicable = activities.applicable;
    });
  }

  public async onRemoveActivityClick(activity: ProjectActivity): Promise<void> {
    if (!this.disabled) {
      const index = this.project.activities.indexOf(activity);
      this.project.activities.splice(index, 1);

      // Also delete from displayArray
      const indexDisplayArray = this.displayActivityArray[this.getDisplayActivityArrayKey(activity.activity_id)].indexOf(activity);

      this.displayActivityArray[this.getDisplayActivityArrayKey(activity.activity_id)].splice(indexDisplayArray, 1);

      // Delete from database
      await this.projectService.deleteProjectActivity(activity.id);

      this.updateActivities();
      this.projectService.setProjectUpdated();
    }
  }

  public async onAddActivityClick(type: number): Promise<void> {
    if (!this.disabled) {
      const newActivity = new ProjectActivity({
        project_id: this.project.id, activity_id: type, applicable: true
      });

      await newActivity.init();

      this.project.activities.push(newActivity);
      this.updateActivities();
      this.projectService.setProjectUpdated();
    }
  }

  private updateActivities(): void {
    if (!this.project || !this.project.activities) {
      return;
    }

    const activityIdArray = [];
    // Slice original array to remove reference
    const array = this.project.activities.slice();

    // Read all activity ID's and put them in list to check later
    for (const element of array) {
      if (!activityIdArray.includes(element.activity_id)) {
        activityIdArray.push({
          id: element.activity_id,
          name: element.activity && element.activity.name,
          applicable: element.applicable || false,
        });
      }
    }

    // Check for all activity ID's in list, put every activity in array to corresponding activity ID
    for (const key of activityIdArray) {
      this.displayActivityArray[this.getDisplayActivityArrayKey(key.id)] = array.filter((activity) => activity.activity_id === key.id);
      this.displayActivityArray[this.getDisplayActivityArrayKey(key.id)].name = key.name;
      this.displayActivityArray[this.getDisplayActivityArrayKey(key.id)].applicable = false;

      for (const item of this.displayActivityArray[this.getDisplayActivityArrayKey(key.id)]) {
        if (item.applicable) {
          this.displayActivityArray[this.getDisplayActivityArrayKey(key.id)].applicable = true;
        }
      }
    }
  }

  private async initAddresses(): Promise<void> {
    this.addressOptions = [];

    for (const address of this.addresses) {
      const addressModel = new Address(address);
      await addressModel.init();

      if (addressModel && addressModel.getDisplayName()) {
        this.addressOptions = [...this.addressOptions, { label: addressModel.getDisplayName(), value: address.id } ];
      }
    }
  }

  private getDisplayActivityArrayKey(id: string | number): string {
    return `key_${id}`;
  }
}

