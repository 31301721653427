import { Component, OnDestroy, OnInit } from '@angular/core';
import { OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { Router } from '@angular/router';
import { takeUntil } from '@node_modules/rxjs/operators';
import { SelectItem } from '@node_modules/primeng/primeng';
import { Subject } from '@node_modules/rxjs';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-user-overview',
  templateUrl: 'user-overview.component.html'
})
export class UserOverviewComponent implements OnInit, OnDestroy {
  public tableOptions: OnlineTableOptions;
  public roleList: SelectItem[] = [];

  private destroy$: Subject<void> = new Subject<void>();

  public constructor(
    private router: Router,
    private api: ApiServiceWithLoaderService,
  ) {}

  public async ngOnInit(): Promise<void> {
    const result = await this.api.get('/role/list').pipe(takeUntil(this.destroy$)).toPromise();

    if (result && result.data) {
      this.roleList = result.data;
    }

    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Naam', name: 'name', filter: { global: true } },
        { title: 'E-mail', name: 'email', filter: { global: true } },
        { title: 'Rol', name: 'roleName', filter: {
            type: 'select',
            column: 'role_id',
            options: this.roleList,
          }
        },
        { title: 'Accountmanager', name: 'isAccountmanager' },
      ],
      endpoint: '/user',
      search: true,
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          row.roleName = row.role.name;
          row.isAccountmanager = !!row.is_accountmanager ? 'Ja' : 'Nee';
        }

        return rows;
      },
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`/admin/users(popup:admin/user/${row.id})`);
  }

  public onAddClick() {
    this.router.navigateByUrl('/admin/users(popup:admin/user/add)');
  }
}
