import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { DataService } from '@shared/services/data.service';
import * as toastr from 'toastr';
import * as moment from 'moment';
import { SignatureComponent } from '@root/src/app/shared/controls/signature/signature.component';
import { QuotationSignService } from '@shared/services/quotation-sign.service';
import { Quotation } from '@domain/models/quotation.model';
import { QuotationChecksComponent } from '@shared/components/quotation-checks/quotation-checks.component';

@Component({
  selector: 'app-project-quotation-sign',
  templateUrl: 'quotation-sign.component.html',
  styleUrls: ['./quotation-sign.component.scss']
})
export class QuotationSignComponent implements OnInit, OnDestroy {
  @ViewChild('clientSignature')
  public clientSignature: SignatureComponent;

  @ViewChild('valuatorSignature')
  public valuatorSignature: SignatureComponent;

  @ViewChild(QuotationChecksComponent)
  public quotationChecks: QuotationChecksComponent;

  public disabled: boolean = false;
  public projectId: string = null;
  public quotationSignId: string = null;
  public today: string = null;
  public loading: boolean = true;
  public submitting: boolean = false;
  public error: boolean = false;
  public submitted: boolean = false;
  public errorMessage: string = null;
  public quotationSignData: any = null;
  public quotation: Quotation = new Quotation({});

  private subscriptionProjectLoaded: Subscription;

  constructor(private syncService: SynchronisationService,
              private projectService: ProjectService,
              private dataService: DataService,
              private route: ActivatedRoute,
              private quotationService: QuotationSignService) {
    this.route.params.subscribe((params) => {
      this.projectId = params['project_id'];
      this.quotationSignId = params['id'];
    });
  }

  public ngOnInit(): void {
    this.getQuotations();
  }

  public getQuotations(): void {
    this.subscriptionProjectLoaded = this.quotationService.getQuotation(this.quotationSignId, this.projectId).subscribe( response => {
      if (response && response.data) {
        this.quotationSignData = response.data;
      }

      if (this.quotationSignData.quotation) {
        this.quotation = this.quotationSignData.quotation;
      }

      this.disabled = true;

      if (response.status === 'error') {
        this.error = true;
        this.errorMessage = response.message;
      }

      this.loading = false;
    }, () => {
      toastr.error('Er ging iets verkeerd', 'Foutmelding');
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  public async submitQuotation(): Promise<void> {
    const checkboxesToCheck = [
        this.quotation.accept_actual_avvv,
        this.quotation.accept_timely_payment,
        this.quotation.accept_client_data,
    ];

    if (this.quotationSignData.project.type === 'private') {
      checkboxesToCheck.push(this.quotation.accept_guarantee_certificate, this.quotation.accept_timely_payment);
    } else if (this.quotationSignData.project.type === 'business') {
      checkboxesToCheck.push(this.quotation.accept_insurance_avvb);
    }

    if (this.quotation.quotation_checks.some((check) => !check.checked)) {
      toastr.error('U dient alle checkboxes aan te vinken om akkoord te gaan met de voorwaarden');

      return;
    }

    this.submitting = true;

    this.quotation.signature_client_date = this.quotation.signature_client_date ? moment(this.quotation.signature_client_date).format('YYYY-MM-DD HH:mm:ss') : moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    this.quotation.signature_valuator_date = this.quotation.signature_valuator_date ? moment(this.quotation.signature_valuator_date).format('YYYY-MM-DD HH:mm:ss') : moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

    this.quotationService.signQuotation({
      quotation_sign_id: this.quotationSignId,
      quotation: this.quotation
    }).subscribe(() => {
      this.submitted = true;
      this.submitting = false;
      toastr.success('Offerte succesvol ondertekend', 'Synchroniseren');
    }, () => {
      this.submitting = false;
      toastr.error('Er ging iets verkeerd', 'Foutmelding');
    });
  }

  public showClientSignatureForm(): void {
    this.today = this.getMoment();

    this.clientSignature.showForm();
  }

  private getMoment(): string {
    return moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  }
}
