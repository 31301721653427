import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { ProjectService } from '@shared/services/project.service';
import { Subject } from 'rxjs';
import { takeUntil } from '@node_modules/rxjs/operators';

@Component({
  selector: 'app-inventory-header',
  templateUrl: 'inventory-header.component.html',
  styleUrls: ['./inventory-header.component.scss']
})
export class InventoryHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input('inventory') public inventory;
  @Input('project') public project;
  @Input('inventories') public inventories;
  @Input('volumeTotal') public volumeTotal;
  @Input('assemblyTotal') public assemblyTotal;
  @Input('packingTotal') public packingTotal;
  @Input('meterboxTotal') public meterboxTotal;
  @Input('disabled') public disabled;

  @Output('inventoryChange') inventoryChange = new EventEmitter;

  public floor;
  public inventoryList: SelectItem[] = [];

  private destroy$ = new Subject();

  constructor(private router: Router,
              private projectService: ProjectService,
              private confirmationService: ConfirmationService) {
  }

  public ngOnInit(): void {
    this.loadInventories();

    this.projectService.getCurrentProject().pipe(takeUntil(this.destroy$)).subscribe((project) => {
      this.project = project;
    });
  }

  public ngOnChanges(): void {
    this.loadInventories();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Check if the given input is a number; otherwise return 0
   * @param input: any
   */
  public checkIfNumber(input: any): number {
    if (!Number(input)) {
      try {
        /** Parse string to float, and format to 2 decimals */
        let result = parseFloat(input);
        return result = Math.round(result * 100) / 100;
      } catch {
        return 0;
      }
    }

    return Number(input) || 0;
  }

  /**
   * When new inventory is selected
   */
  public onInventoryChange(): void {
    this.inventoryChange.emit(this.inventory);
  }

  public onAddClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/inventory(popup:admin/project/inventory/add)');
  }

  /**
   * Delete selected inventory
   */
  public onDeleteClick(): void {
    this.confirmationService.confirm({
      message: 'Wilt u deze inventarisatie verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.projectService.deleteInventory(this.inventory.id);
      }
    });
  }

  public getTotalOfProduct(name: string): number {
    let total = 0;

    for (const inventory of this.inventories) {
      if (inventory.items) {
        for (const item of inventory.items) {
          if (item.name.toLowerCase() === name.toLowerCase()) {
            if (item.amount) {
              total += +item.amount;
            }
          }
        }
      }
    }

    return total;
  }

  /**
   * Load inventories for dropdown
   */
  private loadInventories(): void {
    this.inventoryList = [];

    if (this.inventories) {
      this.inventories.forEach((inventory) => {
        let itemAmount = 0;
        if (inventory.items) {
          itemAmount = inventory.items.reduce((amount, current) => {
            amount += +current.amount || 0;

            return amount;
          }, 0);
        }
        this.inventoryList.push({ label: `${inventory.name} (${+itemAmount ? +itemAmount : 0})`, value: inventory });
      });

      this.inventoryList.sort((a, b) => a.label.localeCompare(b.label));
    }
  }
}
