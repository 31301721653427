import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as toastr from 'toastr';
import { ConfirmationService } from 'primeng/primeng';
import { OfflineTableComponent, OfflineTableOptions } from '@shared/controls/table/offline-table.component';
import { OnlineTableComponent, OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { UserService } from '@shared/services/user.service';
import * as jwt_decode from 'jwt-decode';
import { OnlineStatusService } from '@shared/services/online-status.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { Subject } from 'rxjs';
import { first } from '@node_modules/rxjs/internal/operators';
import { takeUntil } from '@node_modules/rxjs/operators';
import { SettingService } from '@shared/services/setting.service';

@Component({
  selector: 'app-project-overview',
  templateUrl: 'project-overview.component.html',
  styleUrls: ['project-overview.component.scss'],
})
export class ProjectOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('projectSearchTable')
  projectSearchTable: OnlineTableComponent;

  @ViewChild('projectOpenTable')
  projectOpenTable: OfflineTableComponent;

  public projectOpenTableOptions: OfflineTableOptions;
  public projectSearchTableOptions: OnlineTableOptions;
  public displayModal: boolean;
  public selectedProjectType: string;
  public specialties: any[];
  public activities: any[];
  public numberOfSpecialties: number;
  public numberOfActivities: number;
  public onlineMode = navigator.onLine;
  public searchTableReady = false;
  public $searchTableSubject = new Subject<void>();

  private tenantCode: string;
  private destroy$: Subject<void> = new Subject<void>();

  public constructor(
    public api: ApiServiceWithLoaderService,
    private projectService: ProjectService,
    private synchronisationService: SynchronisationService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private onlineStatusService: OnlineStatusService,
    private settingService: SettingService,
  ) {
    this.displayModal = false;
    this.selectedProjectType = 'private';
    this.specialties = [];
    this.activities = [];
    this.numberOfSpecialties = 0;
    this.numberOfActivities = 0;
  }

  public async ngOnInit(): Promise<any> {
    this.api.increaseLoaderValueByOne();

    this.synchronisationService.getTenantCode().pipe(takeUntil(this.destroy$)).subscribe(tenantCode => this.tenantCode = tenantCode);

    this.$searchTableSubject.pipe().subscribe(() => {
      this.loadSearchProjectTable();
    });

    this.loadOpenProjectTable();
    await this.synchronisationService.updateEditingByFlags();
    // Always synchronise when opening project overview
    await this.synchronisationService.synchronise();

    this.api.decreaseLoaderValueByOne();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadOpenProjectTable(): void {
    this.searchTableReady = false;

    this.projectOpenTableOptions = new OfflineTableOptions({
      columns: [
        { title: 'Type', name: 'projectType' },
        { title: 'Referentie', name: 'reference_nr_display' },
        { title: 'Status', name: 'status' },
        { title: 'Klant', name: 'clientName' },
        { title: 'Accountbeheerder', name: 'accountmanagerName' },
        { title: 'In bewerking', name: 'editorName' },
      ],
      withDelete: true,
      url: '/projects',
      tableName: 'projects',
      search: false,
      rowDataTransformer: (rows: any) => {
        for (const row of rows) {
          row.reference_nr_display = row.reference_nr ? row.reference_nr : '*Wordt gegenereerd na synchronisatie*';
          row.status = Project.getStatusName(row.status);
        }

        this.$searchTableSubject.next();

        return rows;
      },
    });
  }

  public loadSearchProjectTable(): void {
    let preGlobalFilter = '';

    if (this.route.snapshot.queryParamMap.get('globalFilter')) {
      preGlobalFilter = this.route.snapshot.queryParamMap.get('globalFilter');

      // Remove globalFilter after search table is filtered
      this.router.navigate(['.'], { relativeTo: this.route, queryParams: {} });
    }

    let columns = this.settingService.getValueString('general.project_table_columns_config');
    columns = JSON.parse(columns);

    columns.map(column => {
      if (column.filter && column.filter.endpoint) {
        column.filter.options = this.api.get(column.filter.endpoint);
      }

      return column;
    });

    this.projectSearchTableOptions = new OnlineTableOptions({
      columns: columns,
      endpoint: '/projects',
      search: true,
      rowDataTransformer: this.transformRows.bind(this),
      withDelete: this.userService.isAdministrator(),
      defaultFilters: { 'global': { value: preGlobalFilter, matchMode: 'contains' } },
    });

    this.searchTableReady = true;
  }

  public async onOpenProjectTableRowClick(data: any): Promise<void> {
    this.router.navigateByUrl('/admin/project/' + data.id + '/client');
  }

  public async onSearchProjectTableRowClick(data: any): Promise<void> {
    if (data.editing_by) {
      this.confirmationService.confirm({
        message: 'Dit project wordt al bewerkt door ' + data.editor.name,
        header: 'Waarschuwing!',
        icon: 'fa fa-question-circle',
        acceptLabel: 'Openen in lees-modus',
        rejectLabel: 'Sluiten',
        accept: async _ => {
          await this.openSelectedProject(data);
        },
      });
    } else {
      await this.openSelectedProject(data);
    }
  }

  public async openSelectedProject(data: any): Promise<void> {
    // Load project data from server and then navigate to detail view
    this.projectSearchTable.loading = true;
    await this.synchronisationService.loadSingleProjectData(data.id);
    this.projectSearchTable.loading = false;

    this.router.navigateByUrl('/admin/project/' + data.id + '/client');
  }

  public async addNewProject(): Promise<void> {
    const project = await this.projectService.newProject();
    this.router.navigateByUrl(`/admin/project/${ project.id }/client`);
  }

  public async closeOpenProjects(): Promise<void> {
    if (!this.projectOpenTable.selectedRows || this.projectOpenTable.selectedRows.length === 0) {
      toastr.warning('Geen projecten geselecteerd');

      return;
    }

    let notSyncableProjectReferences = '';
    for (let i = 0; i < this.projectOpenTable.selectedRows.length; i++) {
      const project: Project = this.projectOpenTable.selectedRows[i];

      if (project.editing_by && +project.editing_by !== +jwt_decode(localStorage.getItem('token')).sub) {
        notSyncableProjectReferences += (project.reference_nr + '\n');
      }
    }

    this.confirmationService.confirm({
      message: notSyncableProjectReferences.length > 0 ?
          'Let op! De volgende projecten worden niet gesynchroniseerd bij het sluiten:\n ' + notSyncableProjectReferences :
          'Let op! De volgende projecten worden gesynchroniseerd en gesloten.',
      header: 'Bevestiging',
      acceptLabel: 'Akkoord',
      rejectLabel: 'Annuleren',
      icon: 'fa fa-question-circle',
      accept: async _ => {
        this.api.increaseLoaderValueByOne();
        this.$searchTableSubject.pipe(first()).subscribe(() => {
          this.loadSearchProjectTable();
        });

        this.onlineStatusService.checkStatus().pipe(takeUntil(this.destroy$)).subscribe(async isOnline => {
          if (isOnline === true) {
            this.projectOpenTable.loading = true;

            const closeProjectIds: string[] = this.projectOpenTable.selectedRows.map(row => row.id);
            const result = await this.synchronisationService.syncToBackend(true, closeProjectIds);

            if (!result) {
              toastr.error('Projecten synchroniseren sluiten mislukt. Controleer uw verbinding.');
              return;
            }

            // Retrieve open projects except selected rows
            for (const row of this.projectOpenTable.rows) {
              if (closeProjectIds.indexOf(row.id) === -1) {
                await this.synchronisationService.loadSingleProjectData(row.id);
              }
            }

            this.loadOpenProjectTable();
            await this.synchronisationService.updateEditingByFlags();
            this.projectOpenTable.loading = false;

            await this.synchronisationService.synchronise();
          } else {
            toastr.warning('Mogelijk geen stabiele internetverbinding. Projecten niet gesynchroniseerd!');
          }

          this.api.decreaseLoaderValueByOne();
        });
      },
    });
  }

  /**
   * Toggle display boolean for showing the print modal
   *
   * @param event
   */
  public toggleModalDisplay(event: any): void {
    this.displayModal = true;
  }

  /**
   * Print the blank invoice based on project type
   */
  public printBlankInvoice(): void {
    this.displayModal = false;

    const divToPrint = document.getElementById('blank_invoice_pdf');
    const head = document.getElementsByTagName('head')[0];
    const popupWin = window.open('', '_blank', 'width=1024,height=768');
    popupWin.document.open();
    popupWin.document.write('<html>' +
        ' <head>' + head.innerHTML + '</head>' +
        ' <body>' +
        '  <img src="/assets/' + this.tenantCode + '/images/logo.png" style="width: 175px;"><br /><br />' +
        '   ' + divToPrint.innerHTML + '' +
        ' </body>' +
        '</html>');

    const elements = popupWin.document.getElementsByTagName('h5');
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute('style', 'font-size: 18px; font-weight: bold;');
    }

    /** Timeout to make sure the window is loaded */
    setTimeout(() => popupWin.print(), 300);
    popupWin.document.close();
  }

  public setSpecialtiesLength(): void {
    if (!(Number(this.numberOfSpecialties) && Number(this.numberOfSpecialties < 15))) {
      return;
    }

    const length = Number(this.numberOfSpecialties);

    this.specialties = [];
    for (let i = 0; i < length; i++) {
      this.specialties.push(i);
    }
  }

  public setActivitiesLength(): void {
    if (!(Number(this.numberOfActivities) && Number(this.numberOfActivities < 15))) {
      return;
    }

    const length = Number(this.numberOfActivities);
    this.activities = [];
    for (let i = 0; i < length; i++) {
      this.activities.push(i);
    }
  }

  public setType(projectType: string): void {
    this.selectedProjectType = projectType;
  }

  private transformRows(rows: Array<any>): any {
    // Determine open projects
    const openProjectIDs = this.projectOpenTable.rows ? this.projectOpenTable.rows.map(project => project.id) : [];
    const selectedProjectIds = this.projectOpenTable.selectedRows ? this.projectOpenTable.selectedRows.map(project => project.id) : [];
    const hideProjectIds = openProjectIDs.filter(projectID => !selectedProjectIds.includes(projectID));
    const existingRows = rows.filter(row => !hideProjectIds.includes(row.id));

    for (const row of existingRows) {
      row.status = Project.getStatusName(row.status);
    }

    return existingRows;
  }
}
