import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { Http } from '@angular/http';
import { environment } from '@environments/environment';
import { map } from '@node_modules/rxjs/internal/operators';
import { Observable } from '@node_modules/rxjs';

@Injectable()
export class OnlineStatusService implements OnDestroy {
  public online$: Observable<boolean>;
  private _online = new BehaviorSubject<boolean>(false);
  private url = '';
  private allowedHttpStatus: number[] = [200, 201, 202, 203, 204, 205, 206];

  constructor(private http: Http) {
    this.online$ = this._online.asObservable();
    this.url = environment.baseUrl + '/auth/login';
    this._online.next(navigator.onLine);
    this.setUpListeners();
    this.checkStatus();
  }

  public checkStatus(): Observable<boolean> {
    if (!navigator.onLine) {
      return of(false);
    } else {
      return this.checkHttpConnection().pipe(
        map(data => {
          return data.status && this.allowedHttpStatus.indexOf(data.status) !== -1;
        })
      );
    }
  }

  public ngOnDestroy() {
    window.removeEventListener('offline', this.switchOffline);
    window.removeEventListener('online', this.switchOnline);
  }

  private checkHttpConnection() {
    return this.http.options(this.url);
  }

  private setUpListeners() {
    window.addEventListener('offline', this.switchOffline.bind(this));
    window.addEventListener('online', this.switchOnline.bind(this));
  }

  private switchOnline() {
    this._online.next(true);
  }

  private switchOffline() {
    this._online.next(false);
  }
}
