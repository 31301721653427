import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-inventory-item',
  templateUrl: 'inventory-item.component.html',
  styleUrls: ['../inventory.scss']
})
export class InventoryItemComponent implements OnInit {
  @Input('inventoryItem') inventoryItem;
  @Input('disabled') disabled;

  @Output('inventoryItemChanged') inventoryItemChanged = new EventEmitter;
  @Output('inventoryItemDeleted') inventoryItemDeleted = new EventEmitter;

  public showCamera = true;

  constructor() {
  }

  public ngOnInit() {
    if (!this.inventoryItem.amount || this.inventoryItem.amount < 0) {
      this.inventoryItem.amount = 0;
    }
  }

  public changeAmount(amount: number) {
    if (!amount) {
      amount = 0;
    }

    const oldAmount = this.inventoryItem.amount;
    this.inventoryItem.amount = +amount;

    if (oldAmount !== this.inventoryItem.amount) {
      this.inventoryItemChanged.emit({
        ...this.inventoryItem,
        increment: oldAmount - +amount,
        subtraction: +amount <= oldAmount
      });
    }
  }

  public addAmount(): void {
    this.inventoryItem.amount += this.getIncrement();
    const obj = { ...this.inventoryItem, increment: this.getIncrement(), subtraction: false };
    this.inventoryItemChanged.emit(obj);
  }

  public removeAmount(): void {
    if (this.inventoryItem.amount === 0) {
      return;
    }

    this.inventoryItem.amount -= this.getIncrement();
    const obj = { ...this.inventoryItem, increment: this.getIncrement(), subtraction: true };
    this.inventoryItemChanged.emit(obj);
  }

  public onDetailsChange(inventoryItem) {
    this.inventoryItemChanged.emit(inventoryItem);
  }

  public onInventoryItemDelete(inventoryItem) {
    this.inventoryItemDeleted.emit(inventoryItem);
  }

  /**
   * Determines the increment/decrement value of the inventory item
   */
  private getIncrement() {
    // Increment boxes by 5. This should be set in the default item,
    // but the current database architecture is not sufficient
    if (this.inventoryItem.name === 'Verhuisdozen' || this.inventoryItem.name === 'Boekendozen' || this.inventoryItem.name === 'MB') {
      return 5;
    }
    return 1;
  }
}
