import { Pipe, PipeTransform } from '@angular/core';
import { AddressFieldValue } from '@domain/models/address-field-value.model';

@Pipe({
  name: 'addressFieldValue'
})
export class AddressFieldValuePipe implements PipeTransform {
  public transform(value: AddressFieldValue): any {
    if (value.address_field.type === 'string') {
      return value.value;
    } else if (value.address_field.type  === 'boolean') {
      let booleanValue = value.value;

      if (typeof value.value === 'string') {
        booleanValue = value.value === '1';
      }

      return booleanValue ? 'Ja' : 'Nee';
    }

    return null;
  }

}
