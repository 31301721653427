import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '@shared/services/user.service';

import * as toastr from 'toastr';

@Injectable()
export class QuotationGuard implements CanActivate {
  constructor(private user: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.user.isAdministrator() || this.user.isAccountManager() || this.user.hasRole('user')) {
      return true;
    }
    toastr.error('Toegang geweigerd');
    return false;
  }
}
