import { DomainModel } from '@domain/domain.model';
import * as uuid from 'uuid/v4';

export class Signature extends DomainModel {
  // Configuration
  public entity = 'signature';
  public table = 'signatures';
  public sync = true;

  // Fields
  public signature_image: string;
  public description: string;
  public work_assignment_id: string;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }
}
