import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { Subscription } from 'rxjs';
import { Subject } from '@node_modules/rxjs';
import { SelectItem } from 'primeng/api';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { Event } from '@domain/models/event.model';
import { ApiLog } from '@domain/models/api-log.model';
import { takeUntil } from '@node_modules/rxjs/operators';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-event-list',
  templateUrl: 'event-list.component.html',
  styleUrls: ['event-list.component.scss'],
})
export class EventListComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public errors: any = {};
  public result: any;
  public showErrors = false;
  public event = new Event({});
  public project = new Project({});
  public disabled = false;
  public environment: object;
  public routeEventId: string;
  public indexes: SelectItem[] = [];
  public mode: any = { isAdd: true };
  public apiLogsOpen: ApiLog[] = [];

  private subscriptionEventLoaded: Subscription;
  private destroy$ = new Subject<boolean>();

  public constructor(private api: ApiServiceWithLoaderService,
                     private router: Router,
                     private route: ActivatedRoute,
                     private projectService: ProjectService,
                     public user: UserService) {
  }

  public async ngOnInit(): Promise<void> {
    this.projectService.getCurrentProject().pipe(takeUntil(this.destroy$)).subscribe((project: Project) => {
      this.project = project;

      this.project.events.filter((item) => {
        return item.seen_at === null;
      })
      .forEach((event) => {
        event.seen_at = new Date();
        this.projectService.saveEvent(event, this.project);

      });
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionEventLoaded) {
      this.subscriptionEventLoaded.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/client');
  }

  public openCreateEvent(): void {
    if (!this.disabled) {
      this.router.navigateByUrl('/admin/project/' + this.project.id + '/client(popup:admin/project/client/events/add)');
    }
  }

  public openApiLog(apiLog: ApiLog): void {
    this.apiLogsOpen.push(apiLog);
  }

  public closeApiLog(apiLog: ApiLog): void {
    this.apiLogsOpen = this.apiLogsOpen.filter(obj => obj !== apiLog);
  }
}
