import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OnlineTableComponent, OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { Subject } from '@node_modules/rxjs';
import { Router } from '@angular/router';
import { QuotationCheckService } from '@features/manage/quotation-checks/services/quotation-check.service';

@Component({
  selector: 'app-quotation-check-list',
  templateUrl: './quotation-check-list.component.html',
  styleUrls: ['./quotation-check-list.component.scss']
})
export class QuotationCheckListComponent implements OnInit, OnDestroy {
  @ViewChild(OnlineTableComponent)
  public onlineTableComponent: OnlineTableComponent;
  public tableOptions: OnlineTableOptions;
  public tableVisible = true;

  private destroy$ = new Subject<void>();

  constructor(private router: Router,
              private quotationCheckService: QuotationCheckService) {
  }

  public ngOnInit(): void {
    this.tableOptions = new OnlineTableOptions({
      sort: {
        column: 'index',
        order: 'asc'
      },
      columns: [
        { title: 'Volgorde', name: 'index', filter: { global: false } },
        { title: 'Label', name: 'label', filter: { global: true } },
        { title: 'Project type', name: 'project_type', filter: { global: false } },
      ],
      endpoint: '/quotation-checks',
      search: true,
      withDelete: true,
      withFilter: false,
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`admin/manage/quotation-checks(popup:admin/manage/quotation-checks/${row.id})`);
  }

  public add(): void {
    this.router.navigateByUrl('/admin/manage/quotation-checks(popup:admin/manage/quotation-checks/add)');
  }
}
