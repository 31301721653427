import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as toastr from 'toastr';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { SelectItem } from 'primeng/api';
import { first, map, tap } from 'rxjs/operators';
import { filter, switchMap } from '@node_modules/rxjs/operators';
import { LocationService } from '@features/manage/locations/services/location.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { Observable } from '@node_modules/rxjs';

@Component({
  selector: 'app-contacts-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss']
})
export class LocationDetailComponent implements OnInit {
  public isAdd = false;
  public loading = false;
  public showErrors = false;
  public form: FormGroup;
  public mode = { isAdd: true };
  public genders: SelectItem[] = [];
  public hasArentTenantSetting$: Observable<{ enabled: boolean }>;
  private endpoint = 'location';

  constructor(private formBuilder: FormBuilder,
              private api: ApiServiceWithLoaderService,
              private router: Router,
              private locationService: LocationService,
              private synchronisationService: SynchronisationService,
              private route: ActivatedRoute) {
    this.isAdd = true;
    this.loading = false;
    this.showErrors = false;

  }

  public ngOnInit(): void {
    this.hasArentTenantSetting$ = this.synchronisationService.myTenant$.pipe(
        map((tenant) => {
          return { enabled: tenant.api_arent };
        }),
    );
    this.initForm();

    this.route.params.pipe(
        filter((param) => !!param.id && param.id !== 'add'),
        first(),
        switchMap(({ id }) => {
          return this.locationService.get(id);
        }),
    ).subscribe((result) => {
      this.isAdd = false;
      this.form.patchValue(result);
      this.loading = false;
    });
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      external_key: [],
      order: [],
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.showErrors = false;
      this.loading = true;

      let request;
      if (this.isAdd) {
        request = this.locationService.post(this.form.value);
      } else {
        request = this.locationService.put(this.route.snapshot.params.id, this.form.value);
      }
      request.subscribe(
          () => {
            toastr.success('Locatie succesvol opgeslagen', 'Locatie');
            this.locationService.setLocationadded();
            this.onCancel();
          },
          (error: any) => {
            this.loading = false;

            if (error.status === 422 && error.json) {
              toastr.warning('Ongeldige invoer', 'Locatie');
            } else {
              toastr.error('Fout bij opslaan locatie', 'Locatie');
            }
          }
      );
    } else {
      this.showErrors = true;
    }
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/manage/locations');
  }
}
