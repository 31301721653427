import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as toastr from 'toastr';
import { ConfirmationService } from 'primeng/primeng';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from '@root/node_modules/rxjs';
import { DataService } from '@shared/services/data.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { DefaultItem } from '@domain/models/default-item.model';
import { takeUntil } from '@node_modules/rxjs/operators';

@Component({
  selector: 'app-materials-detail',
  templateUrl: './materials-detail.component.html',
  styleUrls: ['./materials-detail.component.scss']
})
export class ManageMaterialsDetailComponent implements OnInit, OnDestroy {
  public defaultItem: DefaultItem;
  public isAdd = false;
  public loading = false;
  public showErrors = false;
  public form: FormGroup;
  public mode = { isAdd: true };

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
              private dataService: DataService,
              private api: ApiServiceWithLoaderService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private synchronisationService: SynchronisationService,
              private confirmationService: ConfirmationService,
              private route: ActivatedRoute) {
    this.form = new FormGroup({});
    this.defaultItem = new DefaultItem({}).getData();
    this.isAdd = false;
    this.loading = true;
    this.showErrors = false;

    // Get id of url to edit by route params
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      const id = params['id'];
      this.isAdd = id === 'add';

      if (!this.isAdd) {
        this.api.get('/materials/' + id).pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
          if (result && result.data) {
            this.defaultItem = result.data;
          }

          this.initForm();
          this.loading = false;
        });
      } else {
        this.initForm();
        this.loading = false;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.initForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      name: [ this.defaultItem.name || '' , [Validators.required]],
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      const itemData = this.form.getRawValue();
      this.showErrors = false;
      this.loading = true;

      let request;
      if (this.isAdd) {
        request = this.api.post('/materials', itemData);
      } else {
        request = this.api.patch('/materials/' + this.route.snapshot.params['id'], itemData);
      }

      request.pipe(takeUntil(this.destroy$)).subscribe(_ => {
        toastr.success('Materiaal succesvol opgeslagen', 'Materialen');
        this.router.navigateByUrl('/').then(() => {
          // HACK Use double navigation to force reload..
          this.router.navigateByUrl('/admin/manage/materials');
        });
      }, (error: any) => {
        if (error.status === 422 && error.json) {
          toastr.warning('Ongeldige invoer', 'Materialen');
        } else {
          toastr.error('Fout bij opslaan materiaal', 'Materialen');
        }

        this.loading = false;
      });
    } else {
      this.showErrors = true;
    }
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/manage/materials');
  }
}
