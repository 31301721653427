import { AbstractControl } from '@angular/forms';

export function ValidateNonNegative(control: AbstractControl) {
  const notNegative = new RegExp('^[1-9]+[0-9]*$');

  return notNegative.test(control.value) ? null : {
    ValidateNonNegative: {
      valid: false
    }
  };
}
