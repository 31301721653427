import { Component, OnDestroy, OnInit } from '@angular/core';
import { Picture } from '@domain/models/picture.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ng2ImgMaxService } from 'ng2-img-max';
import * as moment from 'moment';
import * as jwt_decode from 'jwt-decode';
import * as toastr from 'toastr';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { combineLatest } from '@node_modules/rxjs';

@Component({
  selector: 'app-picture-item-detail',
  templateUrl: './picture-item-detail.component.html',
  styleUrls: ['./picture-item-detail.component.scss']
})
export class InventoryPictureItemDetailComponent implements OnInit, OnDestroy {
  public isEdit: boolean;
  public form: FormGroup;
  public disabled: boolean = false;
  public project: Project;

  private pictureId: string;
  private currentPicture: Picture;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private router: Router,
              private api: ApiServiceWithLoaderService,
              private projectService: ProjectService,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private ng2ImgMaxService: Ng2ImgMaxService) {
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      if (this.project) {
        this.disabled = readOnly;
      }
    });

    this.isEdit = false;
  }

  public async ngOnInit(): Promise<void> {
    await this.createForm();

    combineLatest([
      this.route.params,
      this.projectService.getCurrentProject(),
    ]).pipe(takeUntil(this.destroy$)).subscribe(async ([params, project]) => {
      if (project) {
        this.project = project;
        this.disabled = this.project.editing_by && +this.project.editing_by !== +jwt_decode(localStorage.getItem('token')).sub;
      }

      if (!!params && !!params['id'] && params['id'] !== 'add') {
        this.isEdit = true;
        this.pictureId = params['id'];
        await this.setCurrentFile();
      } else {
        this.isEdit = false;
        this.setDefaultImageNameAndTimeStamp();
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/pictures');
  }

  public async submit(): Promise<void> {
    if (!this.form.valid || this.disabled) {
      return;
    }

    let picture;
    if (this.isEdit) {
      picture = {
        ...this.currentPicture,
        ...this.form.value
      };
    } else {
      picture = new Picture({
        ...this.form.value,
        project_id: this.project.id
      });
    }

    if ((this.projectService.calculateBase64FileSize(picture.data) + this.projectService.usedFileSizeBytes) < this.projectService.availableFileSizeBytes) {
      await this.projectService.savePicture(picture);
      this.projectService.setProjectUpdated();
      await this.project.loadPictures();
      await this.projectService.saveProject(this.project);
      this.onCloseClick();
    } else {
      toastr.warning('Geen ruimte beschikbaar. S.p.v. eerst synchroniseren!');
    }
  }

  public uploadFile(fileInput: any): void {
    if (!fileInput.target.files || fileInput.target.files.length === 0 || this.disabled) {
      return;
    }

    this.api.increaseLoaderValueByOne();  // Decreased in ReadFile function

    const file = fileInput.target.files[0];
    const extension = file.name.match(/\.[0-9a-z]+$/i);

    if (file.type.match(/^image\//)) {
      this.ng2ImgMaxService.resize([file], 1600, 1600)
          .pipe(takeUntil(this.destroy$))
          .subscribe((readedFile: any) => {
            this.readFile(extension, readedFile);
          });
    } else {
      this.readFile(extension, file);
    }
  }

  public deleteFile(): void {
    if (!this.disabled) {
      this.form.get('data').patchValue(null);
      this.form.get('extension').patchValue(null);
    }
  }

  private async setCurrentFile(): Promise<void> {
    this.currentPicture = await Picture.query.get(this.pictureId);
    this.form.patchValue(this.currentPicture);
  }

  private setDefaultImageNameAndTimeStamp(): void {
    const toSnakeCase = (value) =>
        value && value
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .join('_');

    const date = moment(new Date());
    const name = `${date.format(`YYYY-MM-DD-HH-mm-ss`)}-${toSnakeCase(this.project.reference_nr || this.project.client.name)}`;
    const timestamp = date.format(`YYYY-MM-DD HH:mm:ss`);

    this.form.get('name').patchValue(name);
    this.form.get('timestamp').patchValue(timestamp);
  }

  private createForm(): void {
    this.form = this.fb.group({
      name: [null, Validators.required],
      description: [null],
      data: [null, Validators.required],
      extension: [null, Validators.required],
      timestamp: [null, Validators.required]
    });
  }

  private readFile(extension: any[], file: any): any {
    const reader = new FileReader();

    reader.addEventListener('load', _ => {
      this.form.get('data').patchValue(reader.result);
      this.form.get('extension').patchValue(extension[0]);

      if (this.isEdit) {
        // Always re-set timestamp when uploading a new picture to existing one
        this.setDefaultImageNameAndTimeStamp();
      }
    }, false);

    reader.readAsDataURL(file);

    this.api.decreaseLoaderValueByOne(); // Increased in uploadFile function
  }
}
