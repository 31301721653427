import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Address } from '@domain/models/address.model';
import { WorkAssignmentAddress } from '@domain/models/work-assignment-address.model';

@Component({
  selector: 'app-workassignment-address-detail',
  templateUrl: './workassignment-address-detail.component.html',
  styleUrls: ['./workassignment-address-detail.component.scss']
})
export class WorkassignmentAddressDetailComponent {
  @Input('addresses')
  addresses: Address[];
  @Input('address')
  address: WorkAssignmentAddress;
  @Input('disabled')
  disabled: boolean;

  @Output('onDeleteAddressClick')
  onDeleteAddressClick = new EventEmitter();

  constructor() {}

  public onDeleteClick(address: any): void {
    if (!this.disabled) {
      this.onDeleteAddressClick.emit(address);
    }
  }
}
