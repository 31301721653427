import { debounceTime } from 'rxjs/operators';
import { Component, Input, Output, OnChanges, SimpleChange, EventEmitter, ViewChild } from '@angular/core';
import { DataTable } from 'primeng/primeng';
import { Subject } from 'rxjs';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

export class OfflineTableOptions {
  public paging = true;
  public search = true;
  public withDelete = false;
  public withSync = false;
  public itemsPerPage = 10;
  public maxSize = 5;
  public numPages = 1;
  public url: string;
  public tableName: string;
  public noResultsMessage = 'Er zijn geen gegevens gevonden';
  public columns: Array<any> = [
    { title: 'Name', name: 'name' }
  ];
  public actions: any;
  public sorting: any = { columns: [] };
  public filtering: any = { globalFilter: '', hiddenColumns: [] };
  public className: Array<string> = ['table-striped', 'table-bordered'];

  public constructor(options: any = {}) {
    Object.assign(this, options);
  }

  public rowDataTransformer: (data: any[]) => any[] = (data) => data;
}

@Component({
  selector: 'em-offline-table',
  templateUrl: 'offline-table.component.html'
})
export class OfflineTableComponent implements OnChanges {
  @Input() options: OfflineTableOptions = new OfflineTableOptions();
  @Input() rows: any;

  @Output() rowClicked: EventEmitter<{ row: any, column: string }> = new EventEmitter<{ row: any, column: string }>();
  @Output() actionClicked: EventEmitter<{ row: any, action: string }> = new EventEmitter<{ row: any, action: string }>();

  @ViewChild('dataTable') public dataTable: DataTable;

  public page = 1;
  public filter = new QueryOptions;
  public totalRecords: number;
  public selectedRows;
  public loading: boolean;
  private apiQueryQueue = new Subject<string>();

  public constructor(private dataService: DataService,
                     private api: ApiServiceWithLoaderService) {
    this.loading = true;

    this.apiQueryQueue.pipe(
      debounceTime(500))
      .subscribe(async (query: string) => {

        this.totalRecords = await this.dataService.count(this.options.tableName, this.filter, this.options.url);

        this.rows = await this.dataService.get(this.options.tableName, this.filter, this.options.url);
        this.rows = this.options.rowDataTransformer(this.rows);

        this.loading = false;
      });
  }

  public loadLazy(event) {
    this.loading = true;
    this.dataTable.rows = event.rows;
    this.filter.pageSize = event.rows;
    this.filter.pageIndex = event.first / event.rows;
    this.apiQueryQueue.next();
  }

  public ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.applyTableOptionsToFilter();
  }

  public applyTableOptionsToFilter() {
    this.filter.columns = [];
    this.options.columns.map(column => {
      this.filter.columns.push({ name: column.name, filter: column.filter, filterMode: 'equals' });
    });

    this.options.filtering.hiddenColumns.map(column => {
      this.filter.columns.push({ name: column.name, filter: column.filter, filterMode: 'equals' });
    });

    this.filter.sortColumn = this.options.filtering.sortColumn || this.filter.sortColumn;
    this.filter.sortOrder = this.options.filtering.sortOrder || this.filter.sortOrder;

    this.onChangeTable();
  }

  public onSearchChanges(event) {
    this.filter.globalFilter = event;
    this.apiQueryQueue.next();
  }

  public onRowClick(event) {
    this.rowClicked.emit(event.data);
  }

  public onActionClick(event) {
    this.actionClicked.emit(event);
  }

  public onChangeTable(config: any = {}, page: any = { page: this.page, itemsPerPage: this.options.itemsPerPage }): any {
    if (config.filtering) {
      Object.assign(this.options.filtering, config.filtering);
    }

    if (config.sorting) {
      Object.assign(this.options.sorting, config.sorting);
    }

    let query = `?_pageIndex=${page.page}&_pageSize=${page.itemsPerPage}`;

    if (this.options.filtering.globalFilter) {
      query = `${query}&_globalFilter=${this.options.filtering.globalFilter}` +
        `&_globalFilterColumns=${this.options.columns.map((column) => column.name).join(',')}`;
    }

    // Create sorte query
    this.options.columns.forEach(column => {
      if (column.sort) {
        query = `${query}&_sortColumn=${column.name}&_sortDirection=${column.sort}`;
      }

      if (column.filter) {
        query = `${query}&${column.name}=${column.filter}`;
      }
    });

    this.options.filtering.hiddenColumns.forEach(column => {
      query = `${query}&${column.name}=${column.filter}`;
    });

    this.apiQueryQueue.next(query);
  }
}
