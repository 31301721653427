import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/primeng';
import { OfflineTableOptions, OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { Address } from '@domain/models/address.model';
import { Subscription } from 'rxjs';
import * as cloneDeep from 'lodash/cloneDeep';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-inventory-address-list',
  templateUrl: 'address-list.component.html',
  styleUrls: ['./address-list.component.scss']
})
export class InventoryAddressListComponent implements OnInit, OnDestroy {
  @ViewChild(OfflineTableComponent) table: OfflineTableComponent;

  public tableOptions: OfflineTableOptions;
  public project = new Project({});
  public addresses: Address[];
  public disabled = true;

  private invoiceFacturationId: number = null;
  private subscriptionProjectLoaded: Subscription;
  private subscriptionAddressAdded: Subscription;

  public constructor(private api: ApiServiceWithLoaderService,
                     private route: ActivatedRoute,
                     private router: Router,
                     private confirmationService: ConfirmationService,
                     private dataService: DataService,
                     private projectService: ProjectService) {
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });

    this.projectService.addressAdded.subscribe(async _ => {
      this.project.addresses = await Address.query.where('project_id').equals(this.project.id).toArray();
    });
  }

  public async ngOnInit(): Promise<void> {
    await this.getInvoiceFacturationId();

    // In case project was loaded
    this.projectService.getCurrentProject().subscribe(async (project: Project) => {
      this.project = project;
      this.setTableOptions();
      this.projectService.setCurrentClient(this.project.client);
      await this.getAddresses();
    });

    // In case address was added (from popup)
    this.projectService.addressAdded.subscribe(async (_) => {
      this.table.onChangeTable();
      await this.getAddresses();
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.subscriptionAddressAdded) {
      this.subscriptionAddressAdded.unsubscribe();
    }
  }

  public async cloneAddress(row: Address): Promise<void> {
    if (!this.disabled) {
      delete row.id;
      row.index = this.table.rows.length + 1;

      await this.projectService.saveAddress(row);
    }
  }

  public onRowClick(data: any): void {
    if (!this.disabled) {
      this.router.navigateByUrl('/admin/project/' + this.project.id + '/address(popup:admin/project/address/' + data.id + ')');
    }
  }

  public onActionClick(data: any): void {
    if (!this.disabled) {
      this[data.action](data.row);
    }
  }

  public onAddClick(): void {
    if (!this.disabled) {
      this.router.navigateByUrl('/admin/project/' + this.project.id + '/address(popup:admin/project/address/add)');
    }
  }

  public openSortModal(): void {
    if (!this.disabled) {
      this.router.navigateByUrl('/admin/project/' + this.project.id + '/address(popup:admin/project/address-sort)');
    }
  }

  public onDeleteClick(): void {
    if (!this.disabled) {
      this.confirmationService.confirm({
        message: 'Wilt u de geselecteerde adressen verwijderen?',
        header: 'Bevestiging',
        icon: 'fa fa-question-circle',
        accept: _ => {
          let availableRows = cloneDeep(this.table.rows);
          this.table.selectedRows.forEach(async (row: any) => {
            this.projectService.deleteAddress(row.id);

            availableRows = availableRows.filter((availableRow: any) => availableRow.id !== row.id);
            if (availableRows && availableRows.length > 0) {
              const movableRows = availableRows.filter((availableRow: any) => availableRow.index > row.index);
              if (movableRows && movableRows.length > 0) {
                movableRows.forEach((movableRow: any) => {
                  movableRow.index--;
                });

                await this.projectService.saveAddresses(movableRows);
              }
            }
          });

          this.table.onChangeTable();
        }
      });
    }
  }

  private async getAddresses(): Promise<void> {
    this.addresses = await Address.query.where('project_id').equals(this.project.id).toArray();
  }

  private async getInvoiceFacturationId() {
    const result = await this.dataService.get('address_types', new QueryOptions(), '/address-type/list');

    for (const item of result) {
      // @todo Refactor to be less ugly, this is nessecary because -facturatieadres- in DB is ID #3, and in frontend it's #5
      if (item.name === 'Facturatie adres' || item.name === 'Facturatieadres') {
        this.invoiceFacturationId = item.id;
      }
    }
  }

  private setTableOptions(): void {
    let action: any[] = [];
    if (!this.disabled) {
      action = [{ title: 'Adres dupliceren', action: 'cloneAddress', icon: 'fa-clone' }];
    }

    this.tableOptions = new OfflineTableOptions({
      paging: false,
      search: false,
      columns: [
        { title: 'Volgorde', name: 'index' },
        { title: 'Type', name: 'address_type_name' },
        { title: 'Woningsoort', name: 'house_type' },
        { title: 'Adres', name: 'address' },
        { title: 'Omschrijving', name: 'description' },
      ],
      actions: action,
      noResultsMessage: 'Er zijn nog geen adressen aangemaakt',
      withDelete: true,
      itemsPerPage: 500,
      url: '/address',
      tableName: 'addresses',
      filtering: { hiddenColumns: [{ name: 'project_id', filter: this.project.id || 0, filterMode: 'equals' }] },
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          if (row.address_type_id !== this.invoiceFacturationId) {
            row.address = `${row.street} ${row.housenumber} ${row.housenumber_add ? '- ' + row.housenumber_add : ''}, ${row.zipcode} ${row.city}, ${row.country}`;
          } else {
            row.address = row.email;
          }

          // TODO: Refactor (and all other list pages with OfflineTableOptions) when nested names like address_type.name are possible
          if (row.address_type && row.address_type.name) {
            row.address_type_name = row.address_type.name;
          }

          if (!row.index) {
            row.index = 'n.v.t.';
          }
        }

        return rows.sort((a, b) => a.index > b.index ? 1 : -1 );
      },
    });
  }
}
