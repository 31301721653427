import { Injectable } from '@angular/core';
import { Observable } from '@node_modules/rxjs';
import { map } from '@node_modules/rxjs/operators';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { QuotationCheck } from '@domain/models/quotation-check.model';

@Injectable({
  providedIn: 'root'
})
export class QuotationCheckService {
  private endpoint = 'quotation-checks';

  constructor(private apiService: ApiServiceWithLoaderService) {
  }

  public indexByType(projectType: string): Observable<QuotationCheck[]> {
    return this.apiService.get(`/${this.endpoint}?project_type=${projectType}`).pipe(map((response) => response.data))
  }

  public get(id: number): Observable<QuotationCheck> {
    return this.apiService.get(`/${this.endpoint}/${id}`).pipe(map((response) => response.data));
  }

  public post(data: Partial<QuotationCheck>): Observable<QuotationCheck> {
    return this.apiService.post(`/${this.endpoint}`, data).pipe(map((response) => response.data));
  }

  public patch(id: number, data: Partial<QuotationCheck>): Observable<QuotationCheck> {
    return this.apiService.patch(`/${this.endpoint}/${id}`, data).pipe(map((response) => response.data));
  }
}
