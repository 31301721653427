import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as toastr from 'toastr';
import { ConfirmationService } from 'primeng/primeng';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from '@root/node_modules/rxjs';
import { DataService } from '@shared/services/data.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { takeUntil } from '@node_modules/rxjs/operators';
import { Tenant } from '@domain/models/tenant.model';
import { TenantConfigQuotationPaymentDetails } from '@domain/models/tenant-config-quotation-payment-details.model';
import { TenantConfigQuotationCompanyDetails } from '@domain/models/tenant-config-quotation-company-details.model';
import { TenantConfigStylesheet } from '@domain/models/tenant-config-stylesheet.model';
import { map } from 'rxjs/operators';
import { SettingService } from '@shared/services/setting.service';

@Component({
  selector: 'app-tenants-detail',
  templateUrl: './tenants-detail.component.html',
  styleUrls: ['./tenants-detail.component.scss']
})
export class ManageTenantsDetailComponent implements OnInit, OnDestroy {
  public isAdd = false;
  public loading = false;
  public showErrors = false;
  public form: FormGroup;
  public mode = { isAdd: true };
  public tenant: Tenant = new Tenant({});

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
      private formBuilder: FormBuilder,
      private dataService: DataService,
      private api: ApiServiceWithLoaderService,
      private router: Router,
      private sanitizer: DomSanitizer,
      private synchronisationService: SynchronisationService,
      private confirmationService: ConfirmationService,
      private route: ActivatedRoute,
      private settingService: SettingService,
  ) {
  }

  public get settingsForm(): FormArray {
    return this.form.get('settings') as FormArray;
  }

  public ngOnInit(): void {
    // Get id of url to edit by route params
    this.route.params.pipe(
        takeUntil(this.destroy$),
    ).subscribe((params: Params) => {
      const id = params['id'];
      this.isAdd = id === 'add';

      if (!this.isAdd) {
        this.api.get('/tenants/' + id).pipe(
            map(result => {
              result = result.data;

              result.settings = result.settings.data;

              return result;
            }),
        ).subscribe((result: Tenant) => {
          this.tenant = result;

          this.initForm();
          this.loading = false;
        });
      } else {
        // Load default settings
        this.settingService.get('/setting?_pageSize=999999').subscribe((result) => {
          this.tenant.settings = result.data.map(setting => {
            setting.value = setting.default_value;

            return setting;
          });

          this.initForm();
          this.loading = false;
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      name: [this.tenant.name || '', [Validators.required]],
      code: [this.tenant.code || '', [Validators.required]],
      host: [this.tenant.host || '', [Validators.required]],
      api_planning_module: [this.tenant.api_planning_module || false, [Validators.required]],
      api_exact_online: [this.tenant.api_exact_online || false, [Validators.required]],
      api_boelaars_en_lambert: [this.tenant.api_boelaars_en_lambert || false, [Validators.required]],
      api_arent: [this.tenant.api_arent || false, [Validators.required]],
      config_stylesheet_primary_color: [this.isAdd ? '' : this.tenant.config_stylesheet.primary_color || '', [Validators.required]],
      config_quotation_company_details_name: [this.isAdd ? '' : this.tenant.config_quotation.company_details.name || '', [Validators.required]],
      config_quotation_company_details_streetname: [this.isAdd ? '' : this.tenant.config_quotation.company_details.streetname || '', [Validators.required]],
      config_quotation_company_details_city: [this.isAdd ? '' : this.tenant.config_quotation.company_details.city || '', [Validators.required]],
      config_quotation_company_details_zipcode: [this.isAdd ? '' : this.tenant.config_quotation.company_details.zipcode || '', [Validators.required]],
      config_quotation_company_details_phone: [this.isAdd ? '' : this.tenant.config_quotation.company_details.phone || '', [Validators.required]],
      config_quotation_company_details_website: [this.isAdd ? '' : this.tenant.config_quotation.company_details.website || '', [Validators.required]],
      config_quotation_payment_details_bic: [this.isAdd ? '' : this.tenant.config_quotation.payment_details.bic || '', [Validators.required]],
      config_quotation_payment_details_email: [this.isAdd ? '' : this.tenant.config_quotation.payment_details.email || '', [Validators.required]],
      config_quotation_payment_details_iban: [this.isAdd ? '' : this.tenant.config_quotation.payment_details.iban || '', [Validators.required]],
      config_quotation_payment_details_kvk: [this.isAdd ? '' : this.tenant.config_quotation.payment_details.kvk || '', [Validators.required]],
      config_quotation_payment_details_vat: [this.isAdd ? '' : this.tenant.config_quotation.payment_details.vat || '', [Validators.required]],
      config_quotation_company_details_post_office_box: [this.isAdd ? '' : this.tenant.config_quotation.company_details.post_office_box || ''],
      settings: this.formBuilder.array([]),
    });

    this.tenant.settings.forEach(setting => {
      const settingForm = this.formBuilder.group({
        id: [null],
        value: [null],
      });

      this.settingsForm.push(settingForm);
    });

    this.settingsForm.patchValue(this.tenant.settings);
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.showErrors = false;
      this.loading = true;

      const itemData = this.form.getRawValue();
      const tenant = new Tenant({
        name: itemData.name,
        code: itemData.code,
        host: itemData.host,
        api_planning_module: itemData.api_planning_module,
        api_exact_online: itemData.api_exact_online,
        api_boelaars_en_lambert: itemData.api_boelaars_en_lambert,
        api_arent: itemData.api_arent,
        config_stylesheet: new TenantConfigStylesheet({
          primary_color: itemData.config_stylesheet_primary_color,
        }),
        config_quotation: {
          company_details: new TenantConfigQuotationCompanyDetails({
            city: itemData.config_quotation_company_details_city,
            name: itemData.config_quotation_company_details_name,
            phone: itemData.config_quotation_company_details_phone,
            streetname: itemData.config_quotation_company_details_streetname,
            website: itemData.config_quotation_company_details_website,
            zipcode: itemData.config_quotation_company_details_zipcode,
            post_office_box: itemData.config_quotation_company_details_post_office_box,
          }),
          payment_details: new TenantConfigQuotationPaymentDetails({
            bic: itemData.config_quotation_payment_details_bic,
            email: itemData.config_quotation_payment_details_email,
            iban: itemData.config_quotation_payment_details_iban,
            kvk: itemData.config_quotation_payment_details_kvk,
            vat: itemData.config_quotation_payment_details_vat,
          })
        },
        settings: itemData.settings,
      });

      let request;
      if (this.isAdd) {
        request = this.api.post('/tenants', tenant);
      } else {
        request = this.api.patch('/tenants/' + this.route.snapshot.params['id'], tenant);
      }

      request.subscribe(_ => {
        toastr.success('Tenant succesvol opgeslagen', 'Tenants');
        this.router.navigateByUrl('/').then(() => {
          // HACK Use double navigation to force reload..
          this.router.navigateByUrl('/admin/manage/tenants');
        });
      }, (error: any) => {
        if (error.status === 422 && error.json) {
          toastr.warning('Ongeldige invoer', 'Tenants');
        } else {
          toastr.error('Fout bij opslaan tenant', 'Tenants');
        }

        this.loading = false;
      });
    } else {
      this.showErrors = true;
    }
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/manage/tenants');
  }
}
