import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertVatPrice'
})
export class ConvertVatPrice implements PipeTransform {
  public transform(value: number|string, showPricesInclVat: boolean, vatRate: number = 21): number {
    return +value === 0 ? null : showPricesInclVat ? +value + (vatRate / 100 * +value) : +value;
  }
}
