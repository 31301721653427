import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineTableComponent, OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { LocationService } from '@features/manage/locations/services/location.service';
import { Subject } from 'rxjs';
import { takeUntil } from '@node_modules/rxjs/operators';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit, OnDestroy {
  @ViewChild(OnlineTableComponent)
  public onlineTableComponent: OnlineTableComponent;
  public tableOptions: OnlineTableOptions;
  public tableVisible = true;

  private destroy$ = new Subject<void>();

  constructor(private router: Router,
              private locationService: LocationService) {
  }

  public ngOnInit(): void {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Volgorde', name: 'order', filter: { global: false } },
        { title: 'Naam', name: 'name', filter: { global: true } },
      ],
      endpoint: '/location',
      search: true,
      withDelete: true,
      withFilter: false,
    });

    // In case location is added (from popup)
    this.locationService.getLocationAdded().pipe(
        takeUntil(this.destroy$)
    ).subscribe(_ => {
      this.onlineTableComponent.loadLazy({ first: 1, page: 1, filters: {}, rows: 10 });
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`admin/manage/locations(popup:admin/manage/locations/${row.id})`);
  }

  public addNewLocation(): void {
    this.router.navigateByUrl('/admin/manage/locations(popup:admin/manage/locations/add)');
  }
}
