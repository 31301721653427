import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address } from '@domain/models/address.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { combineLatest, Subscription } from 'rxjs';
import { Observable, Subject } from '@node_modules/rxjs';
import { environment } from '@environments/environment';
import { SelectItem } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { AddressTemplate } from '@domain/models/address-template.model';
import * as cloneDeep from 'lodash/cloneDeep';
import * as uuid from 'uuid/v4';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { AddressField } from '@domain/models/address-field.model';
import { AddressFieldValue } from '@domain/models/address-field-value.model';

@Component({
  selector: 'app-inventory-address-detail',
  templateUrl: 'address-detail.component.html'
})
export class InventoryAddressDetailComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public errors: any = {};
  public result: any;
  public showErrors = false;
  public addressTypes: SelectItem[] = [];
  public houseTypes: SelectItem[] = [];
  public address = new Address({});
  public project = new Project({});
  public routeAddressId: string;
  public companiesList: SelectItem[] = [];
  public addressFields: AddressField[] = [];
  public companiesList$: Observable<SelectItem[]>;
  public addressTemplates: AddressTemplate[] = [];
  public addressTemplates$: AddressTemplate[] = [];
  public addressTemplatesList: SelectItem[] = [];
  public addressTemplatesListDefault: SelectItem[] = [];
  public disabled = false;
  public isInvoiceAddressType = false;
  public invoiceAddressTypeId: number;
  public environment: object;
  public indexes: SelectItem[] = [];
  public mode: any = { isAdd: true };

  private subscriptionAddressLoaded: Subscription;
  private destroy$: Subject<void> = new Subject<void>();

  public constructor(
      private api: ApiServiceWithLoaderService,
      private router: Router,
      private route: ActivatedRoute,
      private dataService: DataService,
      private projectService: ProjectService,
      private formBuilder: FormBuilder
  ) {
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });

    this.projectService.addressAdded.subscribe(async _ => {
      this.project.addresses = await Address.query.where('project_id').equals(this.project.id).toArray();
    });

    this.invoiceAddressTypeId = null;
    this.environment = environment;
  }

  public async ngOnInit(): Promise<void> {
    const queryOptions = new QueryOptions({
      sortColumn: 'name',
      sortOrder: 'asc',
      usePaging: false,
    });
    const result = await this.dataService.get('house_types', new QueryOptions(queryOptions), '/house-type/list');
    result.forEach((item) => {
      this.houseTypes.push({ label: item.name, value: item.id });
    });

    this.addressFields = await AddressField.query.toArray();

    await this.setAddressTemplates();
    await this.loadLists();

    combineLatest([
      this.projectService.getCurrentProject(),
      this.route.params,
    ]).pipe(
        takeUntil(this.destroy$),
    ).subscribe(([project, params]) => {
      this.routeAddressId = params['id'];
      this.project = project;
      this.initForm();

      if (this.routeAddressId) {
        this.mode.isAdd = false;
        this.projectService.getAddress(this.routeAddressId);

        this.subscriptionAddressLoaded = this.projectService.addressLoaded.subscribe((address: Address) => {
          this.address = address;

          this.initForm();

          this.form.patchValue(this.address);
        });
      } else {
        this.initForm();
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionAddressLoaded) {
      this.subscriptionAddressLoaded.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  public selectAddressTemplate(addressTemplateId: string): void {
    if (addressTemplateId) {
      this.address.id = addressTemplateId;

      this.updateFormToAddressTemplate(this.addressTemplates.find((addressTemplate: AddressTemplate) => addressTemplate.id === addressTemplateId));
    }
  }

  public initForm(): void {
    const addressFieldsFormGroup = this.formBuilder.group({});

    this.addressFields.forEach(addressField => {
      let existingAddressFieldValue = null;
      let value = null;

      if (!this.mode.isAdd && this.address && this.address.address_field_values) {
        existingAddressFieldValue = this.address.address_field_values.find(addressFieldValue => addressFieldValue.address_field_id === (addressField.id));

        if (existingAddressFieldValue) {
          value = existingAddressFieldValue.address_field.type === 'string' ? existingAddressFieldValue.value : existingAddressFieldValue.value === '1';
        }
      }

      addressFieldsFormGroup.addControl(
          addressField.id,
          this.formBuilder.control(value),
      );
    });

    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.address.id),
      project_id: this.formBuilder.control(this.project.id || null),
      address_type_id: this.formBuilder.control({
        value: this.address.address_type_id,
        disabled: this.disabled
      }, Validators.required),
      house_type_id: this.formBuilder.control({ value: this.address.house_type_id, disabled: this.disabled }),
      company_id: this.formBuilder.control({ value: this.addressTemplates, disabled: this.disabled }),
      address_template_id: this.formBuilder.control({
        value: this.address.id || this.addressTemplates,
        disabled: this.disabled
      }),
      street: this.formBuilder.control({ value: this.address.street, disabled: this.disabled }, Validators.required),
      housenumber: this.formBuilder.control({
        value: this.address.housenumber,
        disabled: this.disabled
      }, Validators.required),
      housenumber_add: this.formBuilder.control({ value: this.address.housenumber_add, disabled: this.disabled }),
      zipcode: this.formBuilder.control({ value: this.address.zipcode, disabled: this.disabled }, Validators.required),
      city: this.formBuilder.control({ value: this.address.city, disabled: this.disabled }, Validators.required),
      country: this.formBuilder.control({
        value: this.address.country || 'Nederland',
        disabled: this.disabled
      }, Validators.required),
      type: this.formBuilder.control({ value: this.address.type, disabled: this.disabled }),
      floor: this.formBuilder.control(this.address.floor),
      accessible_with_max: this.formBuilder.control(this.address.accessible_with_max),
      distance_to_building: this.formBuilder.control(this.address.distance_to_building),
      parking_charge: this.formBuilder.control(this.address.parking_charge),
      lat: this.formBuilder.control(this.address.lat),
      lon: this.formBuilder.control(this.address.lon),
      email: this.formBuilder.control(this.address.email, Validators.email),
      description: this.formBuilder.control(this.address.description),
      address_field_values: addressFieldsFormGroup,
    });

    this.isInvoiceAddressType = this.address.address_type_id === this.invoiceAddressTypeId;
    this.updateFormValidation();

    this.form.controls.address_type_id
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((value: any) => {
          this.isInvoiceAddressType = value === this.invoiceAddressTypeId;
          this.updateFormValidation();
        });
  }

  public async onAddressChange() {
    const zipcode = this.form.value.zipcode;
    const housenumber = this.form.value.housenumber;
    const street = this.form.value.street;
    const city = this.form.value.city;

    if ((!zipcode || !housenumber) || (street || city)) {
      return;
    }

    let result = null;

    try {
      result = await this.api
          .post('/address/search', {
            zipcode: this.form.value.zipcode,
            housenumber: this.form.value.housenumber
          })
          .toPromise();
    } catch (error) {

    }

    if (!result) {
      return;
    }

    // Update street and city values
    this.form.patchValue({
      street: result.street,
      city: result.city
    });
  }

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.address = {
        ...this.form.value,
        project_id: this.project.id,
        address_field_values: this.getAddressFieldValueFromRecord(this.form.value.address_field_values, this.address.id)
      };

      if (this.mode.isAdd) {
        this.address.id = uuid();
      }

      if (!this.address.index) {
        const existingAddress = this.project.addresses.find(address => address.id === this.address.id);
        if (existingAddress) {
          this.address.index = existingAddress.index;
        } else {
          this.address.index = this.getIndexNumber();
        }
      }

      await this.projectService.saveAddress(this.address);
      this.projectService.setProjectUpdated();
      await this.project.loadAddresses();
      await this.projectService.saveProject(this.project);

      this.onCloseClick();
    } else {
      this.showErrors = true;
    }
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/address');
  }

  public updateAddressTemplatesList(selectedCompany: string): void {
    if (selectedCompany === null) {
      this.addressTemplatesList = cloneDeep(this.addressTemplatesListDefault);
    } else {
      this.addressTemplatesList = this.addressTemplatesListDefault.filter((address: SelectItem) => {
        if (address) {
          if (address.label.substring(address.label.lastIndexOf('(') + 1, address.label.lastIndexOf(')')) === selectedCompany) {
            return address;
          }
        }

        return;
      });
    }
  }

  private getIndexNumber(): number {
    let index = 1;
    while (this.project.addresses.find(address => address.index === index)) {
      index++;
    }

    return index;
  }

  private updateFormToAddressTemplate(addressTemplate: AddressTemplate): void {
    if (addressTemplate) {
      this.form.patchValue(addressTemplate.getData());
    }
  }

  private async loadLists(): Promise<void> {
    this.result = await this.dataService.get('address_types', new QueryOptions(), '/address-type/list');
    this.result.forEach((item: any) => {
      if (item.name === 'Facturatie adres' || item.name === 'Facturatieadres') {
        this.invoiceAddressTypeId = item.id;
      }

      this.addressTypes.push({ label: item.name, value: item.id });
    }, this);
  }

  private updateFormValidation(): void {
    if (this.isInvoiceAddressType) {
      this.form.get('street').clearValidators();
      this.form.get('street').updateValueAndValidity();

      this.form.get('zipcode').clearValidators();
      this.form.get('zipcode').updateValueAndValidity();

      this.form.get('housenumber').clearValidators();
      this.form.get('housenumber').updateValueAndValidity();

      this.form.get('country').clearValidators();
      this.form.get('country').updateValueAndValidity();

      this.form.get('city').clearValidators();
      this.form.get('city').updateValueAndValidity();

      this.form.get('email').setValidators([Validators.email, Validators.required]);
      this.form.get('email').updateValueAndValidity();
    } else {
      this.form.get('street').setValidators(Validators.required);
      this.form.get('street').updateValueAndValidity();

      this.form.get('zipcode').setValidators(Validators.required);
      this.form.get('zipcode').updateValueAndValidity();

      this.form.get('housenumber').setValidators(Validators.required);
      this.form.get('housenumber').updateValueAndValidity();

      this.form.get('country').setValidators(Validators.required);
      this.form.get('country').updateValueAndValidity();

      this.form.get('city').setValidators(Validators.required);
      this.form.get('city').updateValueAndValidity();

      this.form.get('email').clearValidators();
      this.form.get('email').updateValueAndValidity();
    }
  }

  /**
   * Update form information
   */
  private updateForm() {
    this.form.reset({
      id: this.address.id,
      project_id: this.project.id || null,
      address_type_id: { value: this.address.address_type_id, disabled: this.disabled },
      house_type_id: { value: this.address.house_type_id, disabled: this.disabled },
      address_template_id: { value: this.address.id || this.addressTemplates, disabled: this.disabled },
      street: { value: this.address.street, disabled: this.disabled },
      housenumber: { value: this.address.housenumber, disabled: this.disabled },
      housenumber_add: { value: this.address.housenumber_add, disabled: this.disabled },
      zipcode: { value: this.address.zipcode, disabled: this.disabled },
      city: { value: this.address.city, disabled: this.disabled },
      country: { value: this.address.country || 'Nederland', disabled: this.disabled },
      type: { value: this.address.type, disabled: this.disabled },
      floor: this.address.floor,
      accessible_with_max: this.address.accessible_with_max,
      distance_to_building: this.address.distance_to_building,
      parking_charge: this.address.parking_charge,
      lat: this.address.lat,
      lon: this.address.lon,
      email: this.address.email,
      description: this.address.description,
    });
  }

  private async setAddressTemplates(): Promise<void> {
    this.addressTemplates = await AddressTemplate.query.toArray();

    this.addressTemplates.forEach((addressTemplate: AddressTemplate) => {
      this.addressTemplatesList.push({
        label: addressTemplate.street + ' ' + addressTemplate.housenumber + (addressTemplate.housenumber_suffix ? ' - ' + addressTemplate.housenumber_suffix : '') + ', ' +
            addressTemplate.city + ' (' + addressTemplate.company + ')',
        value: addressTemplate.id
      });

      if (!this.companiesList.find((company: SelectItem) => company.value === addressTemplate.company)) {
        this.companiesList.push({
          label: addressTemplate.company,
          value: addressTemplate.company
        });
      }
    });

    this.companiesList = this.dataService.sortDropdownByLabel(this.companiesList);
    this.addressTemplatesList = this.dataService.sortDropdownByLabel(this.addressTemplatesList);

    this.addressTemplatesListDefault = cloneDeep(this.addressTemplatesList);
  }

  private getAddressFieldValueFromRecord(data: Record<string, string | boolean>, addressId: string): Partial<AddressFieldValue>[] {
    return Object.keys(data).map((addressFieldId) => {
      const existingAddressFieldValue = this.address.address_field_values.find((addressFieldValue) => addressFieldValue.address_field_id === addressFieldId)

      return {
        value: data[addressFieldId],
        id: addressFieldId,
      };
    });
  }
}
