import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';

import { SharedModule } from '@shared/shared.module';

import { DataTableModule, SharedModule as PrimeNGSharedModule, DropdownModule, DialogModule } from 'primeng/primeng';

import { RouterModule } from '@angular/router';
import { SettingsOverviewComponent } from '@features/settings/settings-overview.component';

@NgModule({
  providers: [],
  declarations: [
    SettingsOverviewComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    DataTableModule,
    PrimeNGSharedModule,
    EmendisBlueprintModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    DialogModule
  ],
  exports: [
    SettingsOverviewComponent
  ],
})

export class SettingsModule {
}
