import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { Subject } from '@node_modules/rxjs';
import { takeUntil } from '@node_modules/rxjs/operators';

declare var jQuery: any;

@Component({
  selector: 'app-blank',
  templateUrl: 'blankLayout.component.html'
})
export class LoginLayoutComponent implements AfterViewInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject();

  constructor(private synchronisationService: SynchronisationService) {}

  public ngAfterViewInit(): void {
    jQuery('body').addClass('login-bg');

    this.synchronisationService.getTenantCode()
      .pipe(takeUntil(this.destroy$))
      .subscribe((tenantCode) => {
          jQuery('.login-bg').css('background-image', `url(\'/assets/default/images/login_bg.jpg\')`);
      });
  }

  public ngOnDestroy(): void {
    jQuery('body').removeClass('login-bg')
      .css('background-image', 'none');

    this.destroy$.next();
    this.destroy$.complete();
  }
}
