import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { InventoryClientComponent } from '@features/inventory/client/client.component';
import { InventoryContactListComponent } from '@features/inventory/contact/contact-list.component';
import { InventoryBoardComponent } from '@features/inventory/inventory/inventory-board.component';


@Injectable()
export class InventoryGuard implements CanDeactivate<InventoryBoardComponent> {
  private home = '/admin/projects';
  private popup = 'popup';

  canDeactivate(
    component: InventoryBoardComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState?: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) {

    return true;
  }
}
