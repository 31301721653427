import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { ConfirmationService } from 'primeng/primeng';
import { Inventory } from '@domain/models/inventory.model';
import { InventoryHeaderComponent } from '@features/inventory/inventory/header/inventory-header.component';
import { Material } from '@domain/models/material.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { first } from '@node_modules/rxjs/operators';

@Component({
  selector: 'app-inventory-board',
  templateUrl: 'inventory-board.component.html'
})
export class InventoryBoardComponent implements OnInit, OnDestroy {
  @ViewChild('header') header: InventoryHeaderComponent;

  public project = new Project({});
  public inventories: any;
  public filteredInventoryItems: Array<any>;
  public selectedInventory = new Inventory({});
  public selectedInventoryId = null;
  public volumeTotal: number;
  public assemblyTotal: number;
  public packingTotal: number;
  public meterboxTotal: number;
  public disabled = true;
  public materials: Material[] = [];
  public projectMaterials: any[] = [];

  private subscriptionInventoryDeleted: Subscription;
  private subscriptionInventoryAdded: Subscription;
  private subscriptionProjectLoaded: Subscription;

  public constructor(private projectService: ProjectService,
                     private confirmationService: ConfirmationService,
                     private dataService: DataService) {
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });
  }

  public async ngOnInit(): Promise<void> {
    this.filteredInventoryItems = [];

    // When inventory is deleted reset selected id and inventory then reload all inventories
    this.subscriptionInventoryDeleted = this.projectService.inventoryDeleted.subscribe(() => {
      this.inventories = this.project.inventories;
      this.selectedInventoryId = 0;
      this.getSelectedInventory();
    });

    this.subscriptionInventoryAdded = this.projectService.inventoryAdded.subscribe(async inventoryId => {
      await this.project.loadInventories();
      this.inventories = this.project.inventories;
      this.selectedInventoryId = inventoryId;
      this.getSelectedInventory();
    });

    // Reload when project changes
    this.subscriptionProjectLoaded = this.projectService.getCurrentProject().subscribe(async (project) => {
      this.project = project;
      await this.project.loadInventories();
      this.inventories = this.project.inventories;
      this.projectService.setCurrentClient(this.project.client);

      const localProjectInventories = await Inventory.query.get({ project_id: project.id });

      if (!localProjectInventories || (localProjectInventories && localProjectInventories.length === 0)) {
        await this.projectService.addDefaultInventories(project);
      }

      this.updateTotals();
    });


    this.getSelectedInventory();
    await this.getMaterials();
    await this.getProjectMaterials();
  }

  public async getMaterials(): Promise<void> {
    const queryOptions = new QueryOptions({ usePaging: false });
    this.materials = await this.dataService.get('materials', queryOptions, '/materials');
  }

  public async getProjectMaterials(): Promise<void> {
    this.projectMaterials = await ProjectMaterial.query.where('project_id').equals(this.project.id).toArray();
  }

  /**
   * Check all inventories which match floor and room (default_inventory)
   */
  public onInventoryChange(inventory): void {
    if (!inventory) {
      return;
    }
    this.selectedInventory = inventory;
    this.selectedInventoryId = inventory.id;
    this.getSelectedInventory();
    this.updateTotals();
  }

  /**
   * Search inventory for selected inventory from header
   */
  public getSelectedInventory(): void {
    this.filteredInventoryItems = [];
    if (this.inventories && this.inventories.length > 0) {
      this.inventories.map(inventory => {
        if (inventory.id === this.selectedInventoryId) {
          // this.filteredInventoryItems = inventory.items;
          this.selectedInventory = inventory;

          // Apply custom sorting to items
          // TODO Should be configured using sort order in backend
          this.filteredInventoryItems = inventory.items.sort((a, b) => {
            if (a.name === b.name) {
              return 0;
            }
            // Verhuisdozen or Boekendozen should always be last
            if (a.name === 'Verhuisdozen' || a.name === 'Boekendozen') {
              return 1;
            }
            if (b.name === 'Verhuisdozen' || b.name === 'Boekendozen') {
              return -1;
            }

            return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
          });

          this.filteredInventoryItems.forEach((item) => this.capitalizeItemName(item));
        }
      });
    }
  }


  /**
   * Update inventory item
   */
  public onInventoryItemChange(inventoryItem): void {
    this.updateInventoryItem(inventoryItem);
    this.updateTotals();

    /** Sync with materials */
    this.updateMaterials(inventoryItem);
  }

  /**
   * Delete inventory item
   */
  public onInventoryItemDelete(inventoryItem): void {
    this.removeInventoryItem(inventoryItem);
  }

  /**
   * Handle static item amount changes
   */
  public onAmountChange(): void {
    if (!this.selectedInventory) {
      return;
    }

    this.projectService.updateInventory(this.selectedInventory);
    this.updateTotals();
  }

  /**
   * Create inventory item
   *
   * @param inventoryItem InventoryItem model
   */
  public createInventoryItem(inventoryItem: any): void {
    // Add inventory item to current selected inventory and also update in store
    inventoryItem.inventory_id = this.selectedInventoryId;
    this.selectedInventory.items.push(inventoryItem);
    this.projectService.createOrUpdateInventoryItem(inventoryItem);
    this.getSelectedInventory();
  }

  /**
   * Update inventory item
   *
   * @param inventoryItem InventoruItem model
   */
  public updateInventoryItem(inventoryItem: any): void {
    inventoryItem.inventory_id = this.selectedInventoryId;
    this.projectService.createOrUpdateInventoryItem(inventoryItem);
  }

  /**
   * Remove inventory item
   * @param inventoryItem
   */
  public removeInventoryItem(inventoryItem: any): void {
    this.confirmationService.confirm({
      message: 'Wilt u dit item verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: async _ => {
        await this.projectService.deleteInventoryItem(inventoryItem.id);
        const index = this.filteredInventoryItems.indexOf(inventoryItem);
        this.filteredInventoryItems.splice(index, 1);
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionInventoryDeleted) {
      this.subscriptionInventoryDeleted.unsubscribe();
    }

    if (this.subscriptionInventoryAdded) {
      this.subscriptionInventoryAdded.unsubscribe();
    }

    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  /** Capitalize the first charachter of item name */
  private capitalizeItemName(item: any): void {
    item.name = item.name.charAt(0).toUpperCase() + item.name.slice(1);
  }

  private updateMaterials(inventoryItem): void {
    /** shorthand function */
    const createNewProjectMaterial = (material, inventoryItem) => {
      if (inventoryItem.amount === 0) {
        inventoryItem.amount += inventoryItem.increment;
      }

      const newProjectMaterial = new ProjectMaterial({
        project_id: this.project.id,
        material_id: material.id,
        amount: inventoryItem.amount
      });

      this.projectService.addMaterial(newProjectMaterial);
      this.getProjectMaterials();
    };

    if (!(this.materials.length > 0)) {
      return;
    }

    const name = this.getCorrectName(inventoryItem.name);
    const materialToUpdate = this.materials.find((material) => material.name.toLowerCase() === name.toLowerCase());

    if (!materialToUpdate) {
      return;
    }

    const projectMaterialToUpdate = this.projectMaterials.find((item) => item.material_id === materialToUpdate.id);

    /** If no material exists yet for given ID, create a new one */
    if (projectMaterialToUpdate) {
      if (inventoryItem.subtraction) {
        (projectMaterialToUpdate.amount - inventoryItem.increment >= 0) ? projectMaterialToUpdate.amount -= inventoryItem.increment : null;
      } else {
        projectMaterialToUpdate.amount += inventoryItem.increment;
      }

      this.projectService.updateMaterial(projectMaterialToUpdate);
    } else {
      createNewProjectMaterial(materialToUpdate, inventoryItem);
    }
  }

  private getCorrectName(inventoryName: string): string {
    let convertedName: string;

    /**
     * Needed cuz Arent materials have slightly different names than our own inventory items... :(
     * Luckily, dkb = DKB in Arent so that one is not necessary.
     */
    switch (inventoryName.toLowerCase()) {
      case 'mb':
        convertedName = 'Meterbak';
        break;
      case 'computerbak':
        convertedName = 'Computerbak incl antistatisch matje';
        break;
      case 'rolco':
        convertedName = 'Rolcontainer met nylon wielen';
        break;
      default:
        convertedName = inventoryName;
        break;
    }

    return convertedName;
  }

  private updateTotals(): void {
    this.projectService.getCurrentProject().pipe(first()).subscribe((project) => {
      this.volumeTotal = this.projectService.calculateVolume(project);
      this.packingTotal = this.projectService.calculatePackingTotal(project);
      this.assemblyTotal = this.projectService.calculateAssemblyTotal(project);
      this.meterboxTotal = this.projectService.calculateMeterboxTotal(project);
    });
  }
}
