import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationListComponent } from '@features/manage/locations/location-list/location-list.component';
import { LocationDetailComponent } from '@features/manage/locations/location-detail/location-detail.component';
import { SharedModule } from '@shared/shared.module';
import { ConfirmDialogModule } from '@node_modules/primeng/components/confirmdialog/confirmdialog';
import { DropdownModule } from 'primeng/primeng';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    LocationListComponent,
    LocationDetailComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    DropdownModule,
  ],
  exports: [
    LocationListComponent,
    LocationDetailComponent,
  ]
})
export class LocationModule {
}
