import { DomainModel } from '@domain/domain.model';
import * as uuid from 'uuid/v4';

export class AddressField extends DomainModel {
    // Configuration
    public entity = 'address_field';
    public table = 'address_fields';
    public sync = true;
    public id: string;

    // Fields
    public name: string;
    public type: string;
    public required: boolean;

    // Constructor
    constructor(attributes) {
        super(attributes);

        if (!attributes.id) {
            this.id = uuid();
        }
    }

    public getData(): any {
        return {
            name: this.name || '',
            type: this.type || '',
            required: this.required,
        };
    }
}
