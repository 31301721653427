import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { OfflineTableOptions } from '@shared/controls/table/offline-table.component';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { takeUntil } from '@node_modules/rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-inventories-client-search',
  templateUrl: 'client-search.component.html',
})

export class InventoryClientSearchComponent implements OnInit, OnDestroy {
  public tableOptions: OfflineTableOptions;
  private project: Project;
  private destroy$ = new Subject();

  public constructor(
      private api: ApiServiceWithLoaderService,
      private router: Router,
      private route: ActivatedRoute,
      private formBuilder: FormBuilder,
      private projectService: ProjectService) {
  }

  public async ngOnInit(): Promise<void> {
    this.projectService.getCurrentProject().pipe(
        takeUntil(this.destroy$),
    ).subscribe((project) => {
      this.project = project;
    });

    this.tableOptions = new OfflineTableOptions({
      paging: true,
      columns: [
        { title: 'Naam', name: 'name' },
      ],
      url: '/client',
      tableName: 'clients',
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + (this.project.id || 'new') + '/client');
  }

  public async onRowClick(data: any): Promise<void> {
    await this.projectService.getClient(data.id);

    this.router.navigateByUrl('/admin/project/' + (this.project.id || 'new') + '/client');
  }
}
