import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from '@node_modules/rxjs';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LocationService {
  private locationAdded = new Subject<void>();
  private endpoint = 'location';

  constructor(private apiService: ApiServiceWithLoaderService) {
  }

  public setLocationadded(): void {
    this.locationAdded.next();
  }

  public getLocationAdded(): Observable<void> {
    return this.locationAdded.asObservable();
  }

  public get(id: number): Observable<Location> {
    return this.apiService.get(`/${this.endpoint}/${id}`).pipe(map((response) => response.data));
  }

  public post(data: Partial<Location>): Observable<Location> {
    return this.apiService.post(`/${this.endpoint}`, data).pipe(map((response) => response.data));
  }

  public put(id: number, data: Partial<Location>): Observable<Location> {
    return this.apiService.patch(`/${this.endpoint}/${id}`, data).pipe(map((response) => response.data));
  }
}
