import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';
import { DataService } from '@shared/services/data.service';
import { ManageComponent } from './manage.component';
import { ManageAddressesListComponent } from './addresses/addresses-list/addresses-list.component';
import { ManageAddressesDetailComponent } from './addresses/addresses-detail/addresses-detail.component';
import { ManageContactsListComponent } from './contacts/contacts-list/contacts-list.component';
import { ManageContactsDetailComponent } from './contacts/contacts-detail/contacts-detail.component';
import {
  ManageDefaultInventoriesListComponent
} from '@features/manage/default-inventories/default-inventories-list/default-inventories-list.component';
import {
  ManageDefaultInventoriesDetailComponent
} from '@features/manage/default-inventories/default-inventories-detail/default-inventories-detail.component';
import {
  ManageDefaultItemsDetailComponent
} from '@features/manage/default-items/default-items-detail/default-items-detail.component';
import {
  ManageDefaultItemsListComponent
} from '@features/manage/default-items/default-items-list/default-items-list.component';
import { MultiSelectModule } from '@node_modules/primeng/components/multiselect/multiselect';
import {
  ManageBulkPricesListComponent
} from '@features/manage/bulk-prices/bulk-prices-list/bulk-prices-list.component';
import {
  ManageBulkPricesDetailComponent
} from '@features/manage/bulk-prices/bulk-prices-detail/bulk-prices-detail.component';
import { ManageMaterialsListComponent } from '@features/manage/materials/materials-list/materials-list.component';
import { ManageMaterialsDetailComponent } from '@features/manage/materials/materials-detail/materials-detail.component';
import { ManageOptionsListComponent } from '@features/manage/options/options-list/options-list.component';
import { ManageOptionsDetailComponent } from '@features/manage/options/options-detail/options-detail.component';
import { CheckboxModule } from '@node_modules/primeng/components/checkbox/checkbox';
import { ManageActivitiesListComponent } from '@features/manage/activities/activities-list/activities-list.component';
import {
  ManageActivitiesDetailComponent
} from '@features/manage/activities/activities-detail/activities-detail.component';
import {
  ManageRelationGroupsDetailComponent
} from '@features/manage/relation-groups/relation-groups-detail/relation-groups-detail.component';
import {
  ManageRelationGroupsListComponent
} from '@features/manage/relation-groups/relation-groups-list/relation-groups-list.component';
import { ManageTenantsListComponent } from '@features/manage/tenants/tenants-list/tenants-list.component';
import { ManageTenantsDetailComponent } from '@features/manage/tenants/tenants-detail/tenants-detail.component';
import {
  ManageAddressTypesListComponent
} from '@features/manage/address-types/address-types-list/address-types-list.component';
import {
  ManageAddressTypesDetailComponent
} from '@features/manage/address-types/address-types-detail/address-types-detail.component';
import {
  ManageHouseTypesListComponent
} from '@features/manage/house-types/house-types-list/house-types-list.component';
import {
  ManageHouseTypesDetailComponent
} from '@features/manage/house-types/house-types-detail/house-types-detail.component';
import { QuotationCheckListComponent } from './quotation-checks/quotation-check-list/quotation-check-list.component';
import {
  QuotationCheckDetailComponent
} from './quotation-checks/quotation-check-detail/quotation-check-detail.component';
import { DropdownModule } from 'primeng/primeng';
import { ManageAddressFieldsListComponent } from '@features/manage/address-fields/address-fields-list/address-fields-list.component';
import { ManageAddressFieldsDetailComponent } from '@features/manage/address-fields/address-fields-detail/address-fields-detail.component';

@NgModule({
  providers: [
    DataService
  ],
  declarations: [
    ManageComponent,
    ManageTenantsListComponent,
    ManageTenantsDetailComponent,
    ManageAddressFieldsListComponent,
    ManageAddressFieldsDetailComponent,
    ManageAddressTypesListComponent,
    ManageAddressTypesDetailComponent,
    ManageHouseTypesListComponent,
    ManageHouseTypesDetailComponent,
    ManageAddressesListComponent,
    ManageAddressesDetailComponent,
    ManageContactsListComponent,
    ManageContactsDetailComponent,
    ManageMaterialsListComponent,
    ManageMaterialsDetailComponent,
    ManageOptionsListComponent,
    ManageOptionsDetailComponent,
    ManageActivitiesListComponent,
    ManageRelationGroupsListComponent,
    ManageActivitiesDetailComponent,
    ManageRelationGroupsDetailComponent,
    ManageDefaultInventoriesListComponent,
    ManageDefaultInventoriesDetailComponent,
    ManageDefaultItemsListComponent,
    ManageDefaultItemsDetailComponent,
    ManageBulkPricesListComponent,
    ManageBulkPricesDetailComponent,
    QuotationCheckListComponent,
    QuotationCheckDetailComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    EmendisBlueprintModule,
    MultiSelectModule,
    CheckboxModule,
  ],
})
export class ManageModule {
}
