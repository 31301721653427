import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Setting } from '@domain/models/setting.model';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { catchError } from '@node_modules/rxjs/operators';

@Injectable()
export class SettingService extends ApiServiceWithLoaderService {
  public settingsChanged$: Subject<void> = new Subject();

  protected endpoint = '/setting';
  protected settingResolverEndpoint = '/role/setting';

  /**
   * Retrieve the settings and store them in de local database
   */
  public async loadAll(): Promise<any> {
    const settings = await this.get(this.settingResolverEndpoint)
        .pipe(
            catchError(() => of(false)),
            map((response: any) => response.data),
        )
        .toPromise();

    if (settings) {
      await Setting.query.clear();

      const settingRecords = [];

      for (const key in settings) {
        settingRecords.push({ id: settings[key].key, value: settings[key].value });
      }

      await Setting.query.bulkPut(settingRecords);
    }

    await Setting.loadSettings();
    this.settingsChanged$.next();

    return Promise.resolve(settings);
  }

  /**
   * Set a setting for a tenant
   */
  public set(settingId: string, data: { value: any; tenant_id?: string }): Observable<any> {
    if (typeof data.value !== 'string') {
      data.value = JSON.stringify(data.value);
    }

    this.settingsChanged$.next();

    return this.post(`/setting/set/${settingId}`, data).pipe(
        tap(response => {
          Setting.query.put({ id: response.data.key, value: response.data.value });
          Setting.loadSettings().then(() => {
            this.settingsChanged$.next();
          });
        }),
    );
  }

  /**
   * Get a setting value
   *
   * @param setting: string
   * @return any
   */
  public getValue(setting: string): any {
    return Setting.getBooleanValueByName(setting);
  }

  /**
   * Get a setting value
   *
   * @param setting: string
   * @return any
   */
  public getValueString(setting: string): any {
    return Setting.getValue(setting);
  }
}
