import { Component } from '@angular/core';
import { environment  } from '@environments/environment';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public version: string;
  public environment: any;
  public userName: string = '';

  constructor() {
    this.version = environment.version;
    this.environment = environment;
    this.userName = jwt_decode(localStorage.getItem('token')).name;
  }
}
