import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineTableOptions } from '@shared/controls/table/online-table.component';

@Component({
  selector: 'app-default-inventories-list',
  templateUrl: './default-inventories-list.component.html',
  styleUrls: ['./default-inventories-list.component.scss']
})
export class ManageDefaultInventoriesListComponent implements OnInit {
  public tableOptions: OnlineTableOptions;

  public constructor(private router: Router) {}

  public async ngOnInit(): Promise<void> {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Type', name: 'type', filter: { global: false } },
        { title: 'Naam', name: 'name', filter: { global: true } },
        { title: 'Genereren', name: 'auto_generate_label', filter: { global: false } },
        { title: 'Items', name: 'item_names', filter: { global: false } },
      ],
      endpoint: '/default-inventories',
      search: true,
      withDelete: true,
      withFilter: false,
      sort: { column: 'name' },
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          row.type = row.type === 'private' ? 'Particulier' : 'Zakelijk';
          row.item_names = '';
          row.auto_generate_label = row.auto_generate ? 'Ja' : 'Nee';

          if (row.default_items && row.default_items.length > 0) {
            row.default_items.forEach((item, key) => {
              row.item_names += item.name ? item.name : '-';

              if (!Object.is(row.default_items.length - 1, key)) {
                row.item_names += ', ';
              }
            });
          } else {
            row.item_names = '-';
          }
        }

        return rows;
      },
    });
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`/admin/manage/default-inventories(popup:admin/manage/default-inventories/${row.id})`);
  }

  public addNewItem(): void {
    this.router.navigateByUrl('/admin/manage/default-inventories(popup:admin/manage/default-inventories/add)');
  }
}
