import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as uuid from 'uuid/v4';
import { Project } from '@domain/models/project.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Address } from '@domain/models/address.model';
import { Contact } from '@domain/models/contact.model';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { DefaultInventory } from '@domain/models/default-inventory.model';
import { Inventory } from '@domain/models/inventory.model';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { Client } from '@domain/models/client.model';
import { Observable } from '@node_modules/rxjs';
import { WorkAssignmentAddress } from '@domain/models/work-assignment-address.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { Picture } from '@domain/models/picture.model';
import * as jwt_decode from 'jwt-decode';
import { SelectItem } from 'primeng/api';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { Http } from '@angular/http';
import { AuthService } from '@blueprint/auth/auth.service';
import { Event } from '@domain/models/event.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { SettingService } from '@shared/services/setting.service';
import { DefaultItem } from '@domain/models/default-item.model';
import { DefaultInventoryItem } from '@domain/models/default-inventory-item.model';
import { map, switchMap } from 'rxjs/operators';
import { first } from '@node_modules/rxjs/internal/operators';
import { filter } from '@node_modules/rxjs/operators';
import { AddressFieldValue } from '@domain/models/address-field-value.model';

/**
 * ProjectService
 * This service is designed to provide the project accross different classes and routing
 * IMPORTANT: include this service under provider section in a module.
 */
@Injectable()
export class ProjectService extends ApiServiceWithLoaderService {
  // Constants
  public maxInventories = 100; // Maximum of inventories to show in list
  public maxInventoryItems = 100; // Maximum of items to show in inventory
  public maxListItems = 50; // Maximum of items to show in default inventory list
  public maxNewDefaultItems = 50; // Maximum of default items to push in new inventory
  public event: Event;
  public endpoint = 'project';
  public availableFileSizeBytes = 1000000 * 10; // Maximum upload of 10MB on files page
  public usedFileSizeBytes = 0;
  // Observables
  public addressLoaded = new Subject<any>();
  public addressAdded = new Subject<any>();
  public quotationAdded = new Subject<any>();
  public contactsChanged = new Subject<any>();
  public contactsAdded = new Subject<any>();
  public clientChanged = new Subject<any>();
  public defaultInventoriesLoaded = new Subject<any>();
  public inventoryDeleted = new Subject<any>();
  public inventoryAdded = new Subject<any>();
  public projectIsReadOnly = new BehaviorSubject<boolean>(false);
  public contactTemplatesUpdated = new Subject<any>();
  public eventAdded = new Subject<any>();
  public eventLoaded = new Subject<any>();
  // Models
  public project: Project;
  public address: Address;
  public contact: Contact;
  public dataQuery = new QueryOptions();
  private result;
  private currentClient$ = new Subject<Client>();

  private _project = new BehaviorSubject<Project>(null);

  constructor(private dataService: DataService,
              private router: Router,
              private route: ActivatedRoute,
              private settingService: SettingService,
              private synchronisationService: SynchronisationService,
              http: Http,
              authService: AuthService) {
    super(http, authService);
  }

  public setCurrentProject(project: Project): void {
    this._project.next(project);
  }

  public getCurrentProject(): Observable<Project> {
    return this._project.asObservable().pipe(filter(Boolean));
  }

  public fetchProject(projectId?: string): void {
    if (!projectId) {
      // Ugly check but necessary. Some random refreshes could empty this.project.
      projectId = this.router.url.substring(
          this.router.url.indexOf('project/') + 8,
          this.router.url.lastIndexOf('/')
      );
    }

    if (projectId) {
      this.synchronisationService.loadSingleProjectData(projectId).then(() => {
        this.loadProjectOrCreateNew(projectId);
      });
    } else {
      this.loadProjectOrCreateNew(projectId);
    }
  }

  private loadProjectOrCreateNew(projectId): void {
    from(Project.query.get(projectId))
      .pipe(
        map((project) => {
          if (!project) {
            return new Project({ client_id: null });
          }

          return project;
        }),
        switchMap((project) => {
          return from(project.init()).pipe(map(() => project));
        }),
        switchMap((project) => {
          return this.loadProjectData(project).pipe(map(() => project));
        }),
        first(),
      )
      .subscribe((project) => {
        this.updateProjectReadOnlyStatus(project);
        this.setCurrentProject(project);
      });
  }

  /**
   * CRUD PROJECT
   */
  public async newProject(): Promise<any> {
    const project = new Project({
      id: uuid(),
      is_new: true,
      type: this.settingService.getValueString('general.default_project_type_business') ? 'business' : 'private',
    });

    await this.saveProject(project);

    return project;
  }

  public async addDefaultInventories(project: Project): Promise<void> {
    const defaultInventories = await DefaultInventory.query.toArray();
    let projectInventories = [];
    for (const defaultInventory of defaultInventories) {
      if (defaultInventory && defaultInventory.auto_generate && project.type === defaultInventory.type) {
        projectInventories = [
          ...projectInventories,
          new Inventory({
            name: defaultInventory.name,
            default_inventory_id: defaultInventory.id,
            project_id: project.id,
            floor: '0',
            building: 'gebouw'
          })
        ];
      }
    }

    if (projectInventories.length) {
      await this.saveNewInventory(projectInventories);
    }
  }

  /**
   * Update updated_at field to mark project for synchronisation to backend
   *
   * TODO This synchronisation mark should be more efficient and only applied for real updates
   */
  public setProjectUpdated(): void {
    const project = this._project.getValue();

    if (project && project.id) {
      this.dataService.createOrUpdate('projects', { ...this._project.getValue(), is_changed: true });
    }
  }

  /**
   * Save project with clients
   *
   * @returns {Promise<void>}
   */
  public async saveProject(project?: Project): Promise<void> {
    const newProjectId = await this.dataService.createOrUpdate('projects', project);

    // // Check if private project has default projects loaded
    // if (this.project.type === 'private' &&
    //     this.project.reference_nr &&
    //     this.project.reference_nr.length &&
    //     (!this.project.inventories || this.project.inventories.length === 0)) {
    //   await this.addDefaultInventories();
    // }

    // if (project.status === 'lost') {
    // Remove all names from contacts (AVG)
    // this.anonymizeProjectContacts();
    // }

    this.synchronisationService.setSynchronisingAction(true);

    this.setCurrentProject(project);
  }

  public async saveClientAndProject(project: Project): Promise<void> {
    await this.dataService.createOrUpdate('clients', project.client);

    project.client_id = project.client.id;

    await this.saveProject(project);
  }

  /**
   * Delete project with id
   * @param id Number
   */
  public async deleteProject(id): Promise<void> {
    await this.dataService.delete('projects', id);
  }

  /**
   * Save address
   *
   * @param address: Address
   * @returns Promise<void>
   */
  public async saveAddress(address: Address): Promise<void> {
    await this.dataService.createOrUpdate('addresses', address);

    const project =  this._project.getValue();
    await project.loadAddresses();

    this.setCurrentProject(project);

    this.addressAdded.next();
    this.setProjectUpdated();
  }

  /**
   * Save multiple addresses
   *
   * @param addresses: Address[]
   * @returns Promise<void>
   */
  public async saveAddresses(addresses: Address[]): Promise<void> {
    await Address.query.bulkPut(addresses);
    this.addressAdded.next();
    this.setProjectUpdated();
  }

  /**
   * Save the address index
   *
   * @param address: Address
   * @returns Promise<void>
   */
  public async saveAddressIndex(address: Address): Promise<void> {
    await Address.query.put(address);
  }

  /**
   * Save address
   *
   * @param quotation
   */
  public saveQuotation(quotation: any): void {
    this.setProjectUpdated();

    // Update date string value
    // TODO Refactor to more sustainable solution
    quotation.updateDate();

    this.dataService.createOrUpdate('quotations', quotation).then(() => {
      this.quotationAdded.next();

      const project = this._project.getValue();
      project.loadQuotations().then(() => {
        this.setCurrentProject(project);
      });
    });
  }

  /**
   * Check whether project can be edited by user or not
   *
   * @param project
   */
  public updateProjectReadOnlyStatus(project: Project): void {
    this.projectIsReadOnly.next(
        (project.editing_by && +project.editing_by !== +jwt_decode(localStorage.getItem('token')).sub)
    );
  }

  /**
   * Get address with id
   *
   * @param id Number
   */
  public async getAddress(id: any): Promise<void> {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
    });

    const address = await this.dataService.get('addresses', this.dataQuery, '/address');
    this.address = address[0];
    this.address.init();
    this.addressLoaded.next(this.address);
  }

  /**
   * Delete address with id
   *
   * @param id Number
   */
  public deleteAddress(id: any): void {
    this.setProjectUpdated();
    this.dataService.delete('addresses', id);
  }

  /**
   * Save contact
   *
   * @param contact Contact model
   */
  public async saveContact(contact: any): Promise<void> {
    await this.dataService.createOrUpdate('contacts', contact);

    this.contactsAdded.next();
    this.setProjectUpdated();
  }

  /**
   * Get contact with id
   *
   * @param id Number
   */
  public async getContact(id: any): Promise<void> {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
    });

    const contact = await this.dataService.get('contacts', this.dataQuery, '/contact');
    this.contact = contact[0];
    this.contactsChanged.next(this.contact);
  }

  /**
   * Check if project has contacts
   *
   * @returns {Promise<boolean>}
   * @param projectId
   */
  public async hasContacts(projectId: string): Promise<any> {
    const project = this._project.getValue();

    if (!project || !project.client_id) {
      return false;
    }

    const criteria = { client_id: project.client_id };
    let contact = await this.dataService
        .getWhere('contacts', criteria, `/contact?client_id=${project.client_id}`)
        .then(item => (contact = item));

    return contact !== undefined;
  }

  /**
   * Check if project has a load and deliver address
   *
   * @returns {Promise<boolean>}
   * @param projectId
   */
  public async hasAddresses(projectId: string): Promise<any> {
    if (!projectId) {
      return false;
    }

    const queryOptions = new QueryOptions({
      pageSize: 9999,
      columns: [{ name: 'project_id', filter: projectId, filterMode: 'equals' }]
    });

    const addresses = await this.dataService.get('addresses', queryOptions, `/address`);

    /** ToDo: Make consistent */
    const loadAddress = addresses.find((address) => address.type === 'pickup');
    const deliveryAddress = addresses.find((address) => address.type === 'delivery');

    return loadAddress && deliveryAddress;
  }

  /**
   * Delete contact with id
   *
   * @param id Number
   */
  public deleteContact(id): void {
    this.setProjectUpdated();
    this.dataService.delete('contacts', id);
  }

  /**
   * Get work assignment by id
   */
  public async getWorkAssignment(id: number | string): Promise<any> {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
    });

    const workAssignment = await this.dataService.get('work_assignments', this.dataQuery, '/work_assignment');
    if (workAssignment && workAssignment[0]) {
      workAssignment[0].init();

      return workAssignment[0];
    }
  }

  public async getAllWorkAssignments(id: number | string): Promise<any> {
    this.dataQuery = new QueryOptions({
      columns: [{ name: 'project_id', filter: id, filterMode: 'equals' }]
    });

    const workAssignments = await this.dataService.get('work_assignments', this.dataQuery, '/work_assignment');
    if (workAssignments && workAssignments[0]) {
      for (const assignment of workAssignments) {
        assignment.init();
      }

      return workAssignments;
    }
  }

  /**
   * Save work assignment
   */
  public async saveWorkAssignment(workAssignment: WorkAssignment): Promise<any> {
    this.setProjectUpdated();
    // Update date string value
    // TODO Refactor to more sustainable solution
    workAssignment.updateDate();
    const result = await this.dataService.createOrUpdate('work_assignments', workAssignment);

    // Save items
    for (const item of workAssignment.items) {
      await this.dataService.createOrUpdate('work_assignment_items', item);
    }

    // Save items
    for (const item of workAssignment.address_work_assignments) {
      await this.dataService.createOrUpdate('address_work_assignments', item);
    }
  }

  /**
   * Delete work assignment
   * @param workAssignment
   */
  public async deleteWorkAssignment(workAssignment: WorkAssignment): Promise<void> {
    // First delete items
    await workAssignment.init();
    for (const item of workAssignment.items) {
      await this.dataService.delete('work_assignment_items', item.id);
    }

    this.setProjectUpdated();
    await this.dataService.delete('work_assignments', workAssignment.id);
  }

  public async deleteProjectActivity(activityId: string): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('project_activities', activityId);
  }

  /**
   * Delete work assignment item
   * @param workAssignmentItem
   */
  public async deleteWorkAssignmentItem(workAssignmentItem: WorkAssignmentItem): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('work_assignment_items', workAssignmentItem.id);
  }

  /**
   * Delete work assignment address
   * @param workAssignmentAddress
   */
  public async deleteWorkAssignmentAddress(workAssignmentAddress: WorkAssignmentAddress): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('address_work_assignments', workAssignmentAddress.id);
  }

  /**
   * Save specialties
   *
   * @param specialities Array of specialties
   */
  public async saveSpecialties(specialities: ProjectSpecialty[]): Promise<void> {
    for (const specialty of specialities) {
      // Update date string value
      // TODO Refactor to more sustainable solution
      specialty.updateDate();

      await this.dataService.createOrUpdate('project_specialties', specialty);
    }

    this.setProjectUpdated();
  }

  /**
   * Save project materials
   *
   * @param projectMaterials Array of materials
   */
  public async saveProjectMaterials(projectMaterials): Promise<void> {
    for (const projectMaterial of projectMaterials) {
      await this.dataService.createOrUpdate('project_materials', projectMaterial);
    }
  }

  /**
   * Save Picture
   *
   * @param picture: Picture
   * @returns Promise<void>
   */
  public async savePicture(picture: Picture): Promise<void> {
    await this.dataService.createOrUpdate('pictures', picture);
  }

  public calculateBase64FileSize(base64: string): number {
    const size = +(base64.length * (3 / 4)) - 2;

    return size && size > 0 ? Math.round(size) : 0;
  }

  /**
   * Delete Picture
   *
   * @param pictureId: string
   * @returns Promise<void>
   */
  public async deletePicture(pictureId: string): Promise<void> {
    await this.dataService.delete('pictures', pictureId);
  }

  /**
   * Save activites
   *
   * @param activities Array of activities
   */
  public async saveActivities(activities): Promise<void> {
    for (const activity of activities) {
      let activityObject = activity;

      if (!(activityObject instanceof ProjectActivity)) {
        activityObject = new ProjectActivity(activity);
      }
      // Update date string value
      // TODO Refactor to more sustainable solution
      activityObject.updateDate();
      await this.dataService.createOrUpdate('project_activities', activityObject);
    }
  }

  /**
   * Get client with id
   * @param id Client id
   */
  public async getClient(id): Promise<void> {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
    });

    const client = await this.dataService.get('clients', this.dataQuery, '/client');
    const project = this._project.getValue();
    project.client = client;
    project.client_id = client.id;
    this.setCurrentProject(project);
    this.clientChanged.next(client);
  }

  /**
   * Create or update inventory_item
   * @param inventoryItem InventoryItem model
   */
  public async createOrUpdateInventoryItem(inventoryItem): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.createOrUpdate('inventory_items', inventoryItem);
  }

  /**
   * Write default_inventory_items to inventory_items for specific inventory
   *
   * @param inventories
   */
  public async writeDefaultsToInventoryItems(inventories: Inventory[]): Promise<void> {
    const defaultInventoryIds: number[] = inventories.map((inventory) => inventory.default_inventory_id);
    const defaultInventoryItems: DefaultInventoryItem[] = await this.dataService.whereIn('default_inventory_items', 'default_inventory_id', defaultInventoryIds);
    const defaultItems: DefaultItem[] = await this.dataService.whereIn('default_items', 'id', defaultInventoryItems.map((item) => item.default_item_id));
    let newInventoryItems = [];

    for (const inventory of inventories) {
      const defaultInventoryItemsOfInventory = defaultInventoryItems.filter((item) => {
        return item.default_inventory_id === inventory.default_inventory_id;
      });

      const items = defaultItems.filter((item) => {
        return defaultInventoryItemsOfInventory.some((defaultItem) => defaultItem.default_item_id === item.id)
      });

      for (const defaultItem of items) {
        newInventoryItems = [
          ...newInventoryItems,
          new InventoryItem({
            inventory_id: inventory.id,
            name: defaultItem.name,
            volume: defaultItem.volume,
            meterbox: defaultItem.meterbox
          }),
        ];
      }
    }

    this.setProjectUpdated();
    await this.dataService.bulkCreateOrUpdate('inventory_items', newInventoryItems);

    await this.reloadCurrentProjectInventories();
  }

  /**
   * Delete inventory with id
   * @param id Number
   */
  public async deleteInventoryItem(id): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('inventory_items', id);

    await this.reloadCurrentProjectInventories();
  }

  /**
   * Delete inventory with id and assocciated inventory_items
   *
   * @param id Number
   */
  public async deleteInventory(id): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('inventories', id);

    this.dataQuery = new QueryOptions({
      pageSize: this.maxInventoryItems,
      columns: [{ name: 'inventory_id', filter: id, filterMode: 'equals' }]
    });

    this.result = await this.dataService.get('inventory_items', this.dataQuery, '/inventories');
    this.result.forEach(async inventory => {
      await this.dataService.delete('inventory_items', inventory.id);
    });

    await this.reloadCurrentProjectInventories();

    this.inventoryDeleted.next();
  }

  /**
   * Save new inventory
   * In addition write default_inventory_items to inventory_items
   *
   * @param inventories
   */
  public async saveNewInventory(inventories: Inventory[]): Promise<void> {
    this.setProjectUpdated();
    const newInventories = await this.dataService.bulkCreateOrUpdate('inventories', inventories);
    const filteredInventories = newInventories.filter((inventory) => inventory.default_inventory_id != null);
    await this.writeDefaultsToInventoryItems(filteredInventories);

    if (inventories.length) {
      await this.reloadCurrentProjectInventories();
      this.inventoryAdded.next(newInventories[0].id);
    }
  }

  /**
   * Update inventory
   *
   * @param inventory Inventory model
   */
  public updateInventory(inventory): void {
    this.setProjectUpdated();
    this.dataService.createOrUpdate('inventories', inventory);
  }

  /**
   * Get default inventories
   */
  public async getDefaultInventories(project: Project): Promise<SelectItem[]> {
    const rooms: SelectItem[] = [];
    this.dataQuery = new QueryOptions({
      pageSize: this.maxListItems,
      columns: [{ name: 'type', filter: project.type, filterMode: 'equals' }]
    });

    this.result = await this.dataService.get('default_inventories', this.dataQuery, '/default-inventory/list');
    this.result.forEach(item => {
      rooms.push({ label: item.name, value: item.id });
    }, this);

    this.defaultInventoriesLoaded.next(rooms);

    return rooms;
  }

  /**
   * Update project material
   */
  public updateMaterial(projectMaterial: ProjectMaterial): void {
    this.setProjectUpdated();
    this.dataService.createOrUpdate('project_materials', projectMaterial);
  }

  /**
   * Add project material
   */
  public addMaterial(projectMaterial: ProjectMaterial): void {
    this.setProjectUpdated();
    this.dataService.add('project_materials', projectMaterial);
  }

  /**
   * Calculates total volume of all inventories for the current project
   */
  public calculateVolume(project: Project): number {
    let volumeTotal = 0;
    if (project && project.inventories && project.inventories.length > 0) {
      project.inventories.forEach(inventory => {
        if (inventory) {
          let volume = 0;

          if (inventory.items) {
            inventory.items.forEach(item => {
              volume += item.amount * item.volume || 0;
            });
          }

          volumeTotal += volume;
          inventory.volume = Math.round(volume * 100) / 100;
        }
      });
    }

    return Math.round(volumeTotal * 100) / 100;
  }

  public calculatePackingTotal(project): number {
    if (!project || !project.inventories) {
      return 0;
    }
    let packingTotal = 0;
    for (const inventory of project.inventories) {
      packingTotal += +inventory.packing_amount;
    }
    return packingTotal;
  }

  public calculateAssemblyTotal(project: Project): number {
    if (!project || !project.inventories) {
      return 0;
    }

    let assemblyTotal = 0;
    for (const inventory of project.inventories) {
      if (inventory.items) {
        // for (const inventoryItem of inventory.items) {
        //   assemblyTotal += +(inventoryItem.assemble ? 0.5 : 0) + +(inventoryItem.disassemble ? 0.5 : 0);
        // }

        assemblyTotal += +inventory.assembly_amount;
      }
    }

    return +assemblyTotal;
  }

  public calculateMeterboxTotal(project: Project): number {
    if (!project || !project.inventories) {
      return 0;
    }

    let meterboxTotal = 0;
    for (const inventory of project.inventories) {
      if (inventory.items && inventory.items.length) {
        for (const inventoryItem of inventory.items) {
          // Don't add any boxes when it's a "kast"
          if (!inventoryItem.name.toLowerCase().includes('kast')) {
            meterboxTotal += inventoryItem.amount * inventoryItem.meterbox || 0;
          }
        }
      }
    }

    return meterboxTotal;
  }

  public setCurrentClient(client: Client): void {
    this.currentClient$.next(client);
  }

  public requestInsuranceCertificate(id): Observable<any> {
    return this.post(`/${this.endpoint}/request-insurance-certificate`, { id: id });
  }

  public sendToExact(id): Observable<any> {
    return this.post(`/${this.endpoint}/send-to-exact`, { id: id });
  }


  /**
   * Save event
   *
   * @param event: Event
   * @param project: Project
   * @returns Promise<void>
   */
  public async saveEvent(event: Event, project: Project = null): Promise<void> {
    if (!event.eventable_id && project) {
      event.eventable_id = project.id;
    }

    if (!event.eventable_type && project) {
      event.eventable_type = 'PAVanRooyen\\Domain\\Project\\Project';
    }

    await this.dataService.createOrUpdate('events', event);

    this.eventAdded.next();
    this.setProjectUpdated();
  }

  /**
   * Delete event with id
   *
   * @param id Number
   */
  public deleteEvent(id: any): void {
    this.setProjectUpdated();
    this.dataService.delete('events', id);
  }

  /**
   * Get event with id
   *
   * @param id Number
   */
  public async getEvent(id: any): Promise<void> {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
    });

    const event = await this.dataService.get('events', this.dataQuery, '/events');
    this.event = event[0];
    this.eventLoaded.next(this.event);
  }

  public getCurrentClient(): Observable<Client> {
    return this.currentClient$.asObservable();
  }

  /**
   * Remove all names of people from the project (AVG)
   */
  private anonymizeProjectContacts(): void {
    const project = this._project.getValue();
    Contact.query.where({ 'client_id': project.client_id }).and((contact) => contact.name !== '-').modify({ name: '-' });
  }

  private loadProjectData(project: Project): Observable<any> {
    return combineLatest([
      from(project.loadSpecialties()),
      from(project.loadActivities()),
      from(project.loadInventories()),
      from(project.loadQuotations()),
      from(project.loadAddresses()),
      from(project.loadMaterials()),
      from(project.loadPictures()),
      from(project.loadEvents()),
      from(project.loadEvents()),
    ]);
  }

  private async reloadCurrentProjectInventories(): Promise<void> {
    const project = this._project.getValue();
    await project.loadInventories();
    this.setCurrentProject(project);
  }
}
