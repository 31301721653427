import { BUILD_TIMESTAMP } from '@environments/timestamp';
const packageJson = require('@root/package.json');

export const environment = {
  production: false,
  enableServiceWorker: false,
  name: 'Test',
  applicationName: 'Inventarisatie module',
  baseUrl: '/api',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  features: {
    arent_materials: false
  },
  db_name: 'inventorycomplete_invc_db',
};
