import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '@blueprint/auth/auth.service';
import { UserService } from '@shared/services/user.service';
import { MenuItem } from 'primeng/primeng';
import { SettingService } from '@shared/services/setting.service';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public currentRoute = 'home';
  public onlineMode = navigator.onLine;
  public menuItems: MenuItem[] = [
    {
      automationId: 1,
      label: 'Home',
      icon: 'fa fa-2x fa-home',
      url: 'home',
      command: () => this.navigate('/admin/projects'),
    },
    {
      automationId: 50,
      label: 'Instellingen',
      icon: 'fa fa-2x fa-gears',
      url: 'settings',
      command: () => this.navigate('/admin/settings'),
    },
    {
      automationId: 60,
      label: 'Uitloggen',
      icon: 'fa fa-2x fa-sign-out',
      command: () => this.logout(),
    },
  ];

  public constructor(private router: Router,
                     private authService: AuthService,
                     private user: UserService,
                     private settingService: SettingService) {
  }

  public ngOnInit(): void {
    this.setCurrentRoute(this.router.url);
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.setCurrentRoute(this.router.url);
      }
    });

    if (this.user.isAdministrator()) {
      if (this.settingService.getValueString('general.has_powerbi_report_module')) {
        this.menuItems.push(
            {
              automationId: 20,
              label: 'Rapportage',
              icon: 'fa fa-2x fa-file-text',
              url: 'report',
              command: () => this.navigate('/admin/report'),
            },
        );
      }

      this.menuItems.push(
          {
            automationId: 30,
            label: 'Beheer',
            icon: 'fa fa-2x fa-cogs',
            url: 'manage',
            command: () => this.navigate('/admin/manage'),
          },
          {
            automationId: 40,
            label: 'Gebruikers',
            icon: 'fa fa-2x fa-users',
            url: 'users',
            command: () => this.navigate('/admin/users'),
          },
      );
    }
  }

  public navigate(url: string): void {
    this.setCurrentRoute(url);
    this.router.navigate([url]);
  }

  /**
   * TODO: use blueprint logout method from auth service (couldn't get it to work quick enough :( )
   *
   * @returns {Promise<void>}
   */
  public logout(): void {
    this.authService.logout().subscribe((result: any) => {
      if (result) {
        // Navigate to admin part
        this.router.navigate(['/login']);
      }
    });
  }

  private setCurrentRoute(url: string): void {
    if (url.includes('/admin/projects')) {
      this.currentRoute = 'home';
    } else if (url.includes('/admin/project')) {
      this.currentRoute = 'project';
    } else if (url.includes('/admin/users')) {
      this.currentRoute = 'users';
    } else if (url.includes('/admin/settings')) {
      this.currentRoute = 'settings';
    } else if (url.includes('/admin/manage')) {
      this.currentRoute = 'manage';
    } else if (url.includes('/admin/report')) {
      this.currentRoute = 'report';
    } else {
      this.currentRoute = 'home';
    }
  }
}
