import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as toastr from 'toastr';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from '@root/node_modules/rxjs';
import { DataService } from '@shared/services/data.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { takeUntil } from '@node_modules/rxjs/operators';
import { Specialty } from '@domain/models/specialty.model';
import { Tenant } from '@domain/models/tenant.model';

@Component({
  selector: 'app-options-detail',
  templateUrl: './options-detail.component.html',
  styleUrls: ['./options-detail.component.scss']
})
export class ManageOptionsDetailComponent implements OnInit, OnDestroy {
  public isAdd = false;
  public loading = false;
  public showErrors = false;
  public form: FormGroup;
  public mode = { isAdd: true };
  public option: Specialty = new Specialty({}).getData();
  public relationGroupsList: SelectItem[] = [];
  public tenant: Tenant;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
              private dataService: DataService,
              private api: ApiServiceWithLoaderService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private synchronisationService: SynchronisationService,
              private confirmationService: ConfirmationService,
              private route: ActivatedRoute) {
    this.form = new FormGroup({});
    this.isAdd = false;
    this.loading = true;
    this.showErrors = false;

    this.synchronisationService.myTenant$.pipe(takeUntil(this.destroy$)).subscribe(tenant => {
      // ToDo: Refactor in HTML some if-tenant statement if those attributes are moved to settings MOVC-369
      this.tenant = tenant;
    });

    this.api.get('/relation-groups/list').pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      if (result && result.data) {
        this.relationGroupsList = result.data;
      }
    });

    // Get id of url to edit by route params
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      const id = params['id'];
      this.isAdd = id === 'add';

      if (!this.isAdd) {
        this.api.get('/options/' + id).pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
          if (result && result.data) {
            if (result.data.relation_groups) {
              result.data.relation_group_ids = result.data.relation_groups.map(relationGroup => relationGroup.id);
            }

            this.option = result.data;
          }

          this.initForm();
          this.loading = false;
        });
      } else {
        this.initForm();
        this.loading = false;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.initForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      relation_group_ids: [this.option.relation_group_ids, [Validators.required]],
      name: [this.option.name || '' , [Validators.required]],
      description: [this.option.description || ''],
      index: [this.option.index || null],
      cost_rate: [this.option.cost_rate || 0 , [Validators.required]],
      vat_rate: [this.option.vat_rate || 21 , [Validators.required]],
      has_hours: [this.option.has_hours || false],
      has_remarks: [this.option.has_remarks || false],
      has_date: [this.option.has_date || false],
      exact_code: [ this.option.exact_code || ''],
      sends_notification: [ this.option.sends_notification || false],
      is_default_selected: [ this.option.is_default_selected || false],
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.showErrors = false;
      this.loading = true;

      const itemData = this.form.getRawValue();

      if (itemData.has_hours instanceof Array) {
        itemData.has_hours = itemData.has_hours[0] !== 0;
      }

      if (itemData.has_remarks instanceof Array) {
        itemData.has_remarks = itemData.has_remarks[0] !== 0;
      }

      if (itemData.has_date instanceof Array) {
        itemData.has_date = itemData.has_date[0] !== 0;
      }

      if (itemData.sends_notification instanceof Array) {
        itemData.sends_notification = itemData.sends_notification[0] !== 0;
      }

      let request;
      if (this.isAdd) {
        request = this.api.post('/options', itemData);
      } else {
        request = this.api.patch('/options/' + this.route.snapshot.params['id'], itemData);
      }

      request.subscribe(_ => {
        toastr.success('Optie succesvol opgeslagen', 'Opties');
        this.router.navigateByUrl('/').then(() => {
          // HACK Use double navigation to force reload..
          this.router.navigateByUrl('/admin/manage/options');
        });
      }, (error: any) => {
        if (error.status === 422 && error.json) {
          toastr.warning('Ongeldige invoer', 'Opties');
        } else {
          toastr.error('Fout bij opslaan optie', 'Opties');
        }

        this.loading = false;
      });
    } else {
      this.showErrors = true;
    }
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/manage/options');
  }
}
