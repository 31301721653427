import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Quotation } from '@domain/models/quotation.model';
import { Project } from '@domain/models/project.model';
import { Contact } from '@domain/models/contact.model';
import { Address } from '@domain/models/address.model';
import { Client } from '@domain/models/client.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { DataService } from '@shared/services/data.service';
import { Subject, Subscription } from 'rxjs';
import * as toastr from 'toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import { environment } from '@environments/environment';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { UserService } from '@shared/services/user.service';
import { SignatureComponent } from '@shared/controls/signature/signature.component';
import { TenantConfigQuotationCompanyDetails } from '@domain/models/tenant-config-quotation-company-details.model';
import { TenantConfigQuotationPaymentDetails } from '@domain/models/tenant-config-quotation-payment-details.model';
import { SelectItem } from 'primeng/api';
import { Tenant } from '@domain/models/tenant.model';
import { takeUntil } from '@node_modules/rxjs/operators';
import { SettingService } from '@shared/services/setting.service';
import * as moment from'moment';

@Component({
  selector: 'app-inventory-quotation',
  templateUrl: 'quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class InventoryQuotationComponent implements OnInit, OnDestroy {
  @ViewChild('clientSignature') clientSignature: SignatureComponent;
  @ViewChild('valuatorSignature') valuatorSignature: SignatureComponent;

  public project: Project;
  public client: Client;
  public quotation: Quotation;
  public pickupAddress: Address;
  public deliverAddress: Address;
  public disabled = true;
  public project_id: string;
  public workAssignments: WorkAssignment[];
  public workAssignmentAddresses: any;
  public calculatingBasePrice: boolean;
  public environment: object;
  public localeNL: CalendarLocale = new CalendarLocale();
  public onlineMode = true;
  public showPricesInclVat = false;
  public originalSubtotalPrice = 0;
  public originalTotalPrice = 0;
  public currentUserSign: string = null;
  public totalOptionsPrice = 0;
  public totalOptionsVatPrice = 0;
  public quotationTemplatesList: SelectItem[] = [];
  public sentToExact = false;
  public tenant: Tenant;
  public quotationCompanyDetails: TenantConfigQuotationCompanyDetails;
  public quotationPaymentDetails: TenantConfigQuotationPaymentDetails;
  public quotationCompanyDetailsKeys: Array<Object>;
  public quotationPaymentDetailsKeys: Array<Object>;

  // TODO: Remove when hardcoded PAVR fields are moved to generic solution
  public oldQuotationOptions: { label: string, value: any, customFieldFor: string }[] = [
    { label: 'Inpakken breekbaar', value: 'packing_fragile_price', customFieldFor: null },
    { label: 'Uitpakken breekbaar', value: 'unpacking_fragile_price', customFieldFor: null },
    { label: 'Inpakken', value: 'packing_price', customFieldFor: null },
    { label: 'Voorman', value: 'coordinator_price', customFieldFor: null },
    { label: 'Montage', value: 'assembly_price', customFieldFor: null },
    { label: 'ICT', value: 'mount_unmount_ict', customFieldFor: null },
    { label: 'Kabelbeheer', value: 'cable_management', customFieldFor: null },
    { label: 'Specials', value: 'specials_price', customFieldFor: null },
    { label: 'Vrachtwagen 15m3', value: 'moving_truck_15_cubic', customFieldFor: null },
    { label: 'Vrachtwagen 40m3', value: 'moving_truck_40_cubic', customFieldFor: null },
    { label: 'Verhuizer', value: 'mover', customFieldFor: null },
    { label: 'Verwijderen lichten/gordijnen', value: 'remove_lights_curtains_price', customFieldFor: null },
    { label: 'Piano', value: 'piano_grand_organ_price', customFieldFor: null },
    { label: 'Extra bescherming', value: 'add_protection', customFieldFor: null },
    { label: 'Lift toeslag', value: 'elevator_surcharge', customFieldFor: null },
    { label: 'Piano toeslag', value: 'piano_safe_surcharge', customFieldFor: null },
    { label: 'Verdieping toeslag', value: 'floor_surcharge', customFieldFor: null },
    { label: 'Handyman', value: 'handyman_certificate_price', customFieldFor: 'pavanrooyen' },
    { label: 'Opslag weekprijs', value: 'storage_week_total_price', customFieldFor: 'pavanrooyen' },
    { label: 'Opslag handlingkosten', value: 'storage_handling_price', customFieldFor: 'pavanrooyen' },
    { label: 'Opslag verzekeringstarief', value: 'storage_insurance_price', customFieldFor: 'pavanrooyen' },
    { label: 'Opslag verzekering toaal', value: 'storage_insurance_total_price', customFieldFor: 'pavanrooyen' },
    { label: 'Opslag totaalprijs', value: 'storage_value_total_price', customFieldFor: 'pavanrooyen' },
    { label: 'Parkeerontheffing', value: 'parking_waiver_price', customFieldFor: null },
    { label: 'Garantiecertificaat', value: 'guarantee_certificate_price', customFieldFor: 'pavanrooyen' },
    { label: 'Verwachte aantal opslagcontainers', value: 'estimated_weeks_in_storage', customFieldFor: 'pavanrooyen' },
    { label: 'Opslag per dag per container', value: 'storage_week_text', customFieldFor: 'pavanrooyen' },
    {
      label: 'Door u opgegeven te verzekeren bedrag / verzekeringspremie',
      value: 'storage_value_price',
      customFieldFor: 'pavanrooyen'
    },
  ];

  private contact: Contact;
  private addresses: Address[];
  private mainAddress: Address;
  private quotationSent: boolean;
  private subscriptionProjectLoaded: Subscription;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private synchronisationService: SynchronisationService,
              private projectService: ProjectService,
              private settingService: SettingService,
              private userService: UserService,
              private dataService: DataService,
              private api: ApiServiceWithLoaderService,
              private router: Router,
              private route: ActivatedRoute) {
    this.onlineMode = navigator.onLine;

    this.synchronisationService.myTenant$.pipe(takeUntil(this.destroy$)).subscribe(tenant => {
      this.tenant = tenant;

      // ToDo: Refactor and replace for settings
      if (this.tenant.code !== 'harrievanerp') {
        this.quotationTemplatesList = [
          {
            label: 'Standaard',
            value: 'standard',
          },
        ];
      } else {
        this.quotationTemplatesList = [
          {
            label: 'Standaard',
            value: 'standard',
          },
          {
            label: 'Nacalculatie',
            value: 'recalculation',
          },
          {
            label: 'Opslag',
            value: 'storage',
          },
        ];
      }
    });

    this.quotationCompanyDetails = this.synchronisationService.getTenantConfigQuotation('company_details');
    this.quotationCompanyDetailsKeys = Object.keys(this.quotationCompanyDetails).map(key => {
      return { key: key };
    });

    this.quotationPaymentDetails = this.synchronisationService.getTenantConfigQuotation('payment_details');
    this.quotationPaymentDetailsKeys = Object.keys(this.quotationPaymentDetails).map(key => {
      return { key: key };
    });

    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });

    this.calculatingBasePrice = false;
    this.quotation = new Quotation({});
    this.contact = new Contact({});
    this.client = new Client({});
    this.project = new Project({});
    this.environment = environment;
    this.addresses = [];
    this.quotationSent = false;

    this.subscriptionProjectLoaded = this.projectService.getCurrentProject().subscribe((project: Project) => {
      this.project = project;

      this.setVatChoice(this.project.type);

      if (this.project.client) {
        this.client = this.project.client;
      } else {
        this.client = new Client({});
      }

      this.totalOptionsPrice = this.getTotalOptionsPrice();
      this.totalOptionsVatPrice = this.getTotalOptionsVatPrice();

      this.addresses = this.project.addresses;

      // Determine main address and pickup/deliver address
      for (const address of this.addresses) {
        switch (address.type) {
          case 'main':
            this.mainAddress = address;
            break;
          case 'delivery':
            this.deliverAddress = address;
            break;
          case 'pickup':
            this.pickupAddress = address;
            break;
          default:
            break;
        }
      }

      // Get first quotation
      if (this.project.quotations.length > 0) {
        this.quotation = this.project.quotations[0];

        if (this.tenant.code === 'pavanrooyen' && !this.quotation.guarantee_certificate_price) {
          this.quotation.guarantee_certificate_price = 85;
        }

        // Update storage value total to make sure it's filled
        this.updateTotalStorageValue(this.quotation.storage_value_price);
      } else {
        this.quotation = new Quotation({ project_id: this.project.id, estimated_distance_km: null });
      }

      this.quotation.coordinator_price = null;

      this.fillInvoiceContact();
      this.retrieveActivityAddresses();
      this.initForm();
      this.projectService.setCurrentClient(this.project.client);

      if (this.project.type === 'private') {
        this.quotation.guarantee_certificate_price = this.quotation.guarantee_certificate_price || 0;
      } else {
        this.quotation.guarantee_certificate_price = 0;
      }
    });
  }

  public getProjectStatus(): string {
    return Project.getStatusName(this.project.status);
  }

  public async ngOnInit(): Promise<void> {
    this.currentUserSign = await this.userService.getUserSign();

    this.route.params.subscribe(async (params: any) => {
      const result = await this.projectService.getAllWorkAssignments(params['project']);
      if (result) {
        this.workAssignments = result;
        await this.initWorkAssignmentAddresses();
      }
    });
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.quotationSent) {
      this.quotation.status = 'already_queued';
    }

    await this.saveQuotation();

    this.destroy$.next();
    this.destroy$.complete();
  }

  public async updateBasePrice(): Promise<void> {
    this.quotation.calculated_volume = this.projectService.calculateVolume(this.project);
    this.quotation.distance = this.quotation.distance_km * 1000;

    const volume = this.quotation.total_volume > 0 ? this.quotation.total_volume : this.quotation.calculated_volume;
    const distance = this.quotation.estimated_distance_km > 0 ? this.quotation.estimated_distance_km : this.quotation.distance / 1000;

    if (distance > 0 && volume > 0) {
      // Calculated base price, based on distance and total volume from bulk price list
      await this.dataService.findBulkPrice(distance, volume)
          .then(item => {
            if (item === undefined) {
              /** If the program enters here, for some reason item is undefined. For debugging see data.service #findBulkPrices() and log the variables. */
              return toastr.error('De verhuisprijs kan niet worden berekend op dit moment.');
            }

            /** If item is defined set the base price to the item price and update the total prices */
            if (item) {
              if (!this.quotation.manually_changed_base_price) {
                this.quotation.base_price = item.price;
              }

              this.updateTotal();
            }
            return;
          });
    }
  }

  public async updateStorageWeeks(): Promise<void> {
    if (this.quotation.storage_week_text !== null || this.quotation.storage_week_text !== '') {
      await this.calculateStorageWeekPrice(this.quotation.estimated_weeks_in_storage, this.quotation.storage_week_text);
    }

    if (this.quotation.storage_value_price !== null) {
      await this.updateTotalStorageValue(this.quotation.storage_value_price);
    }
  }

  public async updateStorageWeekPrice(): Promise<void> {
    if (this.quotation.estimated_weeks_in_storage !== null) {
      await this.calculateStorageWeekPrice(this.quotation.estimated_weeks_in_storage, this.quotation.storage_week_text);
    }
  }

  public setOriginalSubtotalPrice(price: number): void {
    this.originalSubtotalPrice = price;
  }

  public setOriginalTotalPrice(price: number): void {
    this.originalTotalPrice = price;
  }

  public async overwriteTotal(originalPrice: number, eventValue: string): Promise<void> {
    const newPrice = this.convertFocusEventValueToNumber(eventValue);

    if (originalPrice !== newPrice) {
      const originalBasePrice = this.quotation.base_price || 0;

      if (this.showPricesInclVat) {
        this.quotation.base_price = originalBasePrice + ((newPrice - originalPrice) / 121 * 100);
      } else {
        this.quotation.base_price = originalBasePrice + (newPrice - originalPrice);
      }

      this.updatedBasePriceManually();

      await this.updateTotal();
    }
  }

  public async updateTotal(eventValue?: string, attribute?: string): Promise<void> {
    if (eventValue === '' || eventValue === undefined) {
      eventValue = '€ 0,00';
    }

    if (eventValue && attribute) {
      const value = this.convertFocusEventValueToNumber(eventValue);
      this.quotation[attribute] = this.showPricesInclVat ? value / 121 * 100 : value;
    }

    this.quotation.subtotal_price = 0;
    this.quotation.subtotal_price += this.quotation.base_price || 0;
    this.quotation.subtotal_price += this.quotation.packing_fragile_price || 0;
    this.quotation.subtotal_price += this.quotation.unpacking_fragile_price || 0;
    this.quotation.subtotal_price += this.quotation.packing_price || 0;
    this.quotation.subtotal_price += this.quotation.coordinator_price || 0;
    this.quotation.subtotal_price += this.quotation.assembly_price || 0;
    this.quotation.subtotal_price += this.quotation.mount_unmount_ict || 0;
    this.quotation.subtotal_price += this.quotation.cable_management || 0;
    this.quotation.subtotal_price += this.quotation.specials_price || 0;
    this.quotation.subtotal_price += this.quotation.moving_truck_15_cubic || 0;
    this.quotation.subtotal_price += this.quotation.moving_truck_40_cubic || 0;
    this.quotation.subtotal_price += this.quotation.mover || 0;
    this.quotation.subtotal_price += this.quotation.remove_lights_curtains_price || 0;
    this.quotation.subtotal_price += this.quotation.piano_grand_organ_price || 0;
    this.quotation.subtotal_price += this.quotation.add_protection || 0;
    this.quotation.subtotal_price += this.quotation.elevator_surcharge || 0;
    this.quotation.subtotal_price += this.quotation.piano_safe_surcharge || 0;
    this.quotation.subtotal_price += this.quotation.floor_surcharge || 0;
    this.quotation.subtotal_price += this.quotation.handyman_certificate_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_week_total_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_handling_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_insurance_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_insurance_total_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_value_total_price || 0;
    this.quotation.subtotal_price += this.quotation.parking_waiver_price || 0;

    if (this.project.type === 'private') {
      this.quotation.subtotal_price += this.quotation.guarantee_certificate_price || 0;
    }

    this.quotation.subtotal_price += +this.quotation.custom_option_1_price || 0;
    this.quotation.subtotal_price += +this.quotation.custom_option_2_price || 0;
    this.quotation.subtotal_price += +this.quotation.custom_option_3_price || 0;
    this.quotation.subtotal_price += +this.quotation.custom_option_4_price || 0;
    this.quotation.subtotal_price += +this.quotation.custom_option_5_price || 0;

    this.quotation.subtotal_price += +this.totalOptionsPrice || 0;

    // Calculate vat (excluded parking waiver, storage insurance)
    const calcVat = (amount) => {
      return amount ? Math.round((amount * 100) * 0.21) / 100 : 0;
    };

    this.quotation.vat_price = 0;
    this.quotation.vat_price += calcVat(this.quotation.base_price);
    this.quotation.vat_price += calcVat(this.quotation.packing_fragile_price);
    this.quotation.vat_price += calcVat(this.quotation.unpacking_fragile_price);
    this.quotation.vat_price += calcVat(this.quotation.packing_price);
    this.quotation.vat_price += calcVat(this.quotation.coordinator_price);
    this.quotation.vat_price += calcVat(this.quotation.assembly_price);
    this.quotation.vat_price += calcVat(this.quotation.mount_unmount_ict);
    this.quotation.vat_price += calcVat(this.quotation.cable_management);
    this.quotation.vat_price += calcVat(this.quotation.specials_price);
    this.quotation.vat_price += calcVat(this.quotation.moving_truck_15_cubic);
    this.quotation.vat_price += calcVat(this.quotation.moving_truck_40_cubic);
    this.quotation.vat_price += calcVat(this.quotation.mover);
    this.quotation.vat_price += calcVat(this.quotation.remove_lights_curtains_price);
    this.quotation.vat_price += calcVat(this.quotation.piano_grand_organ_price);
    this.quotation.vat_price += calcVat(this.quotation.add_protection);
    this.quotation.vat_price += calcVat(this.quotation.elevator_surcharge);
    this.quotation.vat_price += calcVat(this.quotation.piano_safe_surcharge);
    this.quotation.vat_price += calcVat(this.quotation.floor_surcharge);
    this.quotation.vat_price += calcVat(this.quotation.storage_week_total_price);
    this.quotation.vat_price += calcVat(this.quotation.storage_handling_price);

    this.quotation.vat_price += calcVat(this.quotation.storage_value_total_price);

    this.quotation.vat_price += calcVat(+this.quotation.custom_option_1_price);
    this.quotation.vat_price += calcVat(+this.quotation.custom_option_2_price);
    this.quotation.vat_price += calcVat(+this.quotation.custom_option_3_price);
    this.quotation.vat_price += calcVat(+this.quotation.custom_option_4_price);
    this.quotation.vat_price += calcVat(+this.quotation.custom_option_5_price);

    this.quotation.vat_price += calcVat(+this.quotation.handyman_certificate_price);
    this.quotation.vat_price += calcVat(+this.quotation.storage_insurance_total_price);
    this.quotation.vat_price += calcVat(+this.quotation.guarantee_certificate_price);

    this.quotation.vat_price += this.totalOptionsVatPrice;

    this.quotation.total_price = this.quotation.subtotal_price + this.quotation.vat_price;
  }

  public async submitQuotation(status: string): Promise<void> {
    if (!this.disabled) {
      switch (status) {
        case 'pending':
          this.quotationSent = true;
          this.quotation.status = 'queued';
          break;
        case 'booked':
          this.quotationSent = true;
          this.quotation.status = 'quotation_sent';
          break;
        default:
          break;
      }

      toastr.info('Offerte wordt verstuurd..', 'Synchronisatie');

      await this.saveProjectQuotation(status);
    }
  }

  public async updateTotalStorageValue(amount: any): Promise<void> {
    const rounded = amount / 1000;
    const basePrice = (rounded * 0.75);
    this.quotation.storage_value_total_price = basePrice;

    await this.updateTotal();

    if (this.quotation.estimated_weeks_in_storage !== null && this.quotation.estimated_weeks_in_storage > 0) {
      await this.calculateStorageForPeriod(this.quotation.estimated_weeks_in_storage, basePrice);
    }
  }

  public showClientSignatureForm(): void {
    this.clientSignature.showForm();
  }

  public showValuatorSignatureForm(): void {
    this.valuatorSignature.showForm();
  }

  public loadMySignature(): void {
    this.quotation.signature_valuator_image = this.currentUserSign;
  }

  public setVatChoice(projectType: string): void {
    this.showPricesInclVat = projectType === 'private';

    if (Number(this.settingService.getValueString('general.default_project_vat_calculation')) === 0 || Number(this.settingService.getValueString('general.default_project_vat_calculation')) === 1) {
      this.showPricesInclVat = Number(this.settingService.getValueString('general.default_project_vat_calculation')) === 1;
    }
  }

  public async resetCustomBasePrice(): Promise<void> {
    this.quotation.manually_changed_base_price = false;

    await this.updateBasePrice();
  }

  public updatedBasePriceManually(): void {
    this.quotation.manually_changed_base_price = true;
  }

  public async initForm(): Promise<void> {
    await this.updateDistance();
    await this.updateBasePrice();
    await this.updateAssemblyPrice();
    await this.updatePackingPrice();
    await this.updateTotal();
  }

  public setProjectDate(property: keyof Quotation): void {
    if (!this.quotation[property]) {
      this.quotation[property] = moment().toDate();
    }
  }

  public async submitToExact(): Promise<void> {
    toastr.info('Exact Online actie gestart...', 'Exact Online');

    await this.saveProjectQuotation();

    toastr.info('Facuur wordt verstuurd naar Exact Online...', 'Exact Online');

    this.projectService.sendToExact(this.project.id).subscribe(() => {
      this.sentToExact = true;
      toastr.info('Facuur is verstuurd.', 'Exact Online');
    }, () => {
      toastr.error('Facuur is niet verstuurd.', 'Exact Online');
    });
  }

  private async calculateStorageWeekPrice(daysOfStorage: number, storageWeekPrice: any): Promise<void> {
    if ((typeof Number(storageWeekPrice) === 'number' &&
            typeof Number(storageWeekPrice) !== 'undefined') &&
        typeof Number(daysOfStorage) === 'number') {
      this.quotation.storage_week_total_price = Number(storageWeekPrice) * Number(daysOfStorage);

      await this.updateTotal();
    } else {
      toastr.error('Alleen cijfers zijn toegestaan bij `Verwachte aantal dagen in opslag`.', 'Foute invoer');
    }
  }

  private async calculateStorageForPeriod(daysOfStorage: number, basePrice: number): Promise<void> {
    if (typeof Number(daysOfStorage) === 'number') {
      if (this.project.type === 'private') {
        daysOfStorage -= (4 * 7) * 6; // Amount of days is 4 weeks * 7 days * 6 months
      }

      const numberOfPeriods = Math.ceil(daysOfStorage / (4 * 7));
      const totalStoragePrice = basePrice * numberOfPeriods;

      totalStoragePrice > 0 ? this.quotation.storage_value_total_price = totalStoragePrice : this.quotation.storage_value_total_price = 0;

      await this.updateTotal();
    } else {
      toastr.error('Alleen cijfers zijn toegestaan bij `Waarde van opslag`.', 'Foute invoer');
    }
  }

  private async initWorkAssignmentAddresses(): Promise<void> {
    this.workAssignmentAddresses = [];
    for (const assignment of this.workAssignments) {
      if (assignment.address_work_assignments.length > 0) {
        for (const address of assignment.address_work_assignments) {
          this.workAssignmentAddresses.push(address);
        }
      }
    }

    await this.updateDistance();
  }

  private async saveQuotation(): Promise<void> {
    if (!this.disabled) {
      this.quotation.estimated_distance = this.quotation.estimated_distance_km > 0 ? this.quotation.estimated_distance_km * 1000 : null;

      await this.projectService.saveQuotation(this.quotation);
    }
  }

  private updateAssemblyPrice(): void {
    if (this.quotation.assembly_price || this.disabled) {
      return;
    }

    this.quotation.assembly_price = this.projectService.calculateAssemblyTotal(this.project) * 45;
  }

  private updatePackingPrice(): void {
    if (this.quotation.packing_complete_price || this.disabled) {
      return;
    }

    this.quotation.packing_complete_price = this.projectService.calculatePackingTotal(this.project) * 45;
  }

  private async updateDistance(): Promise<void> {
    if (!this.pickupAddress || !this.deliverAddress || this.disabled || !navigator.onLine) {
      return;
    }

    let result = null;
    try {
      result = await this.api
          .post('/address/distance', {
            from: {
              street: this.pickupAddress.street,
              housenumber: this.pickupAddress.housenumber,
              zipcode: this.pickupAddress.zipcode,
              city: this.pickupAddress.city,
              country: this.pickupAddress.country
            },
            to: {
              street: this.deliverAddress.street,
              housenumber: this.deliverAddress.housenumber,
              zipcode: this.deliverAddress.zipcode,
              city: this.deliverAddress.city,
              country: this.deliverAddress.country
            }
          })
          .toPromise();
    } catch (e) {
      toastr.error('Afstand kon niet worden berekend', 'Afstand berekenen');
    }

    if (result && result.data && result.data > 0) {
      this.quotation.distance = result.data;
    } else {
      this.quotation.distance = 0;
    }

    this.quotation.distance_km = this.quotation.distance / 1000;
    this.quotation.estimated_distance_km = this.quotation.estimated_distance > 0 ? this.quotation.estimated_distance / 1000 : null;
  }

  /**
   * Fill the text field for contact in invoice based on facturation addresses
   */
  private fillInvoiceContact(): void {
    const facturationAddresses: Address[] = [];
    for (const address of this.addresses) {
      if (address.type === 'post' || address.type === 'post') {
        facturationAddresses.push(address);
      }
    }

    if (!this.quotation.invoice_attn) {
      this.quotation.invoice_attn = facturationAddresses.length > 0 ? facturationAddresses[0].email : '';
    }

    if (!this.quotation.invoice_name) {
      this.quotation.invoice_name = facturationAddresses.length > 0 ? facturationAddresses[0].getDisplayName() : '';
    }
  }

  /** Asynchroniously fetch the address belonging to the activity */
  private async retrieveActivityAddresses(): Promise<void> {
    for (const activity of this.project.activities) {
      if (activity.address_id) {
        const address: Address = await this.dataService.getById('addresses', activity.address_id);

        activity.address = address ? address.getDisplayName() : null;
      }
    }
  }

  private getTotalOptionsPrice(): number {
    return this.project.specialties
        .filter(specialty => specialty.applicable)
        .map(specialty => (specialty.hours_estimate || 0) * (specialty.specialty.cost_rate || 0))
        .reduce((prev: number, current: number) => +current + +prev, 0) || 0;
  }

  private getTotalOptionsVatPrice(): number {
    return this.project.specialties
        .filter(specialty => specialty.applicable)
        .map(specialty => (specialty.hours_estimate || 0) * ((specialty.specialty.cost_rate || 0) * (+specialty.specialty.vat_rate / 100)))
        .reduce((prev: number, current: number) => +current + +prev, 0) || 0;
  }

  private convertFocusEventValueToNumber(eventValue: string): number {
    const value = +eventValue.substring(2).replace('.', '').replace(',', '.') || 0;

    return eventValue.charAt(0) === '-' ? +`-${value}` : value;
  }

  private async saveProjectQuotation(status: string = null): Promise<void> {
    await this.saveQuotation();

    if (status) {
      this.project.status = status;
    }

    await this.projectService.saveProject(this.project);

    try {
      const result = await this.synchronisationService.syncToBackend(false, [this.project.id]);

      if (result) {
        this.api.get('/project/' + this.project.id).subscribe((response) => {
          if (response && response.data) {
            if (response.data.projects && response.data.projects[0]) {
              this.project.editing_by = response.data.projects[0].editing_by;
              this.project.reference_nr = response.data.projects[0].reference_nr;
            }

            if (response.data.quotations && response.data.quotations[0]) {
              this.quotation.created_at = response.data.quotations[0].created_at;

              if (this.project.quotations && this.project.quotations[0]) {
                this.project.quotations[0].created_at = response.data.quotations[0].created_at;
              }
            }

            toastr.success(status ? 'Offerte succesvol verstuurd' : 'Project succesvol opgeslagen', 'Synchronisatie');

          } else {
            toastr.error('Project niet succesvol opgehaald', 'Synchronisatie');
          }
        });
      }
    } catch (error) {
      toastr.error(`Er is een fout opgetreden, ${status ? 'offerte is niet verstuurd' : 'project niet opgeslagen'}`, 'Synchronisatie');
      this.synchronisationService.shouldSync = true;
    }
  }
}
