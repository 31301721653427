import { DomainModel } from '@domain/domain.model';

export class QuotationCheck extends DomainModel {
  // Configuration
  public entity = 'quotation-check';
  public table = 'quotation_checks';
  public syc = true;
  public id: string;
  public label: string;
}
