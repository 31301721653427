import { DomainModel } from '@domain/domain.model';

export class AddressType extends DomainModel {

  // Configuration
  public entity = 'address-type';
  public table = 'address_types';
  public sync = true;

  // Fields
  public name: string;
  public code?: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
