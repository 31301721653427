import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineTableOptions } from '@shared/controls/table/online-table.component';

@Component({
  selector: 'app-house-types-list',
  templateUrl: './house-types-list.component.html',
  styleUrls: ['./house-types-list.component.scss']
})
export class ManageHouseTypesListComponent implements OnInit {
  public tableOptions: OnlineTableOptions;

  public constructor(private router: Router) {}

  public async ngOnInit(): Promise<void> {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Naam', name: 'name', filter: { global: true } },
      ],
      endpoint: '/house-types',
      search: true,
      withDelete: true,
      withFilter: false,
      sort: { column: 'name' },
      rowDataTransformer: (rows) => {
        return rows;
      },
    });
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`/admin/manage/house-types(popup:admin/manage/house-types/${row.id})`);
  }

  public addNewItem(): void {
    this.router.navigateByUrl('/admin/manage/house-types(popup:admin/manage/house-types/add)');
  }
}
