import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as toastr from 'toastr';
import { ConfirmationService } from 'primeng/primeng';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from '@shared/services/data.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ContactTemplate } from '@domain/models/contact-template.model';
import { ProjectService } from '@shared/services/project.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-contacts-detail',
  templateUrl: './contacts-detail.component.html',
  styleUrls: ['./contacts-detail.component.scss']
})
export class ManageContactsDetailComponent implements OnInit {
  public contact: ContactTemplate = new ContactTemplate({}).getData();
  public isAdd: boolean = false;
  public loading: boolean = false;
  public showErrors: boolean = false;
  public form: FormGroup;
  public mode = { isAdd: true };
  public genders: SelectItem[] = [];

  constructor (private formBuilder: FormBuilder,
               private dataService: DataService,
               private api: ApiServiceWithLoaderService,
               private router: Router,
               private sanitizer: DomSanitizer,
               private projectService: ProjectService,
               private synchronisationService: SynchronisationService,
               private confirmationService: ConfirmationService,
               private route: ActivatedRoute) {
    this.isAdd = false;
    this.loading = false;
    this.showErrors = false;
  }

  public async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.loadLists();
    this.initForm();

    // Get id of url to edit by route params
    this.route.params.subscribe((params: Params) => {
      const id = params['id'];
      this.isAdd = id === 'add';

      if (!this.isAdd) {
        this.api.get('/contact-template/' + id).subscribe((result: any) => {
          this.contact = result;
          this.initForm();
          this.loading = false;
        });
      } else {
        this.loading = false;
        this.initForm();
      }
    });
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      company: [ this.contact.company || '' , [Validators.required, Validators.pattern('^[a-zA-Z0-9_ .-]*$')]],
      name: [this.contact.name || '', Validators.required],
      initials: [this.contact.initials || '', Validators.required],
      gender: [this.contact.gender || 'F', Validators.required],
      email: [this.contact.email || '', Validators.required],
      phone: [this.contact.phone || '', Validators.required],
      mobile: [this.contact.mobile || ''],
      remarks: [this.contact.remarks || '']
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.contact = this.form.value;
      this.showErrors = false;
      this.loading = true;

      const contactData = { ...this.form.value, ...this.form.value };

      let request;
      if (this.isAdd) {
        request = this.api.post('/contact-template', contactData);
      } else {
        request = this.api.patch('/contact-template/' + this.route.snapshot.params['id'], contactData);
      }
      request.subscribe(
        () => {
          toastr.success('Contactpersoon succesvol opgeslagen', 'Contact templates');
          this.projectService.contactTemplatesUpdated.next();
          this.router.navigateByUrl('/admin/manage/contacts');
        },
        (error: any) => {
          this.loading = false;

          if (error.status === 422 && error.json) {
            toastr.warning('Ongeldige invoer', 'Contact templates');
          } else {
            toastr.error('Fout bij opslaan contactpersoon', 'Contact templates');
          }
        }
      );
    } else {
      this.showErrors = true;
    }
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/manage/contacts');
  }

  private async loadLists(): Promise<void> {
    this.genders = [
      { label: 'Familie', value: 'F' },
      { label: 'De heer', value: 'M' },
      { label: 'Mevrouw', value: 'V' },
    ];
  }
}
