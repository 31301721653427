import { Pipe, PipeTransform } from '@angular/core';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { Observable } from 'rxjs';
import { map } from '@node_modules/rxjs/operators';

@Pipe({
  name: 'addMyTenant'
})
export class AddMyTenantPipe implements PipeTransform {

  constructor(private api: ApiServiceWithLoaderService,
              private synchronisationService: SynchronisationService, ) {
  }

  public transform(path: string): Observable<string> {
    return this.synchronisationService.getTenantCode().pipe(map((tenantCode) => {
      return path.substring(0, path.indexOf('assets') + 7) + tenantCode + (path.substring(path.indexOf('assets') + 6, path.length));
    }));
  }
}
