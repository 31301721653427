import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataService } from '@shared/services/data.service';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { Subscription } from 'rxjs';
import { Subject } from '@node_modules/rxjs';
import { environment } from '@environments/environment';
import { SelectItem } from 'primeng/api';

import { UserService } from '@shared/services/user.service';
import { User } from '@domain/models/user.model';
import * as uuid from 'uuid/v4';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { Event } from '@domain/models/event.model';
import * as moment from '@node_modules/moment';

@Component({
  selector: 'app-event-detail',
  templateUrl: 'event-detail.component.html'
})
export class EventDetailComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public errors: any = {};
  public result: any;
  public showErrors = false;
  public event = new Event({});
  public project = new Project({});
  public disabled = false;
  public environment: object;
  public routeEventId: string;
  public indexes: SelectItem[] = [];
  public mode: any = { isAdd: true };

  private subscriptionEventLoaded: Subscription;
  private destroy$: Subject<void> = new Subject<void>();

  public constructor(private api: ApiServiceWithLoaderService,
                     private router: Router,
                     private route: ActivatedRoute,
                     private dataService: DataService,
                     private projectService: ProjectService,
                     private formBuilder: FormBuilder,
                     private userService: UserService) {
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });

    this.projectService.eventAdded.subscribe(async _ => {
      this.project.events = await Event.query.where('eventable_id').equals(this.project.id).and((item) => item.eventable_type === 'PAVanRooyen\\Domain\\Project\\Project').toArray();
    });

    this.environment = environment;
  }

  public async ngOnInit(): Promise<void> {
    // Get id of project or event to edit / create by route params
    this.route.params.subscribe((params: Params) => {
      this.routeEventId = params['id'];

      if (this.routeEventId) {
        this.mode.isAdd = false;
        this.projectService.getEvent(this.routeEventId);

        this.subscriptionEventLoaded = this.projectService.eventLoaded.subscribe((event: Event) => {
          this.event = event;
          this.updateForm();
        });
      }

      this.projectService.getCurrentProject().subscribe((project: Project) => {
        this.project = project;
        this.initForm();
        this.updateForm();
      });
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionEventLoaded) {
      this.subscriptionEventLoaded.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.event.id),
      event: this.formBuilder.control(this.event.event),
    });
  }

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.event = {
        ...this.form.value,
        eventable_id: this.project.id,
        eventable_type: 'PAVanRooyen\\Domain\\Project\\Project'
      };

      if (this.mode.isAdd) {
        this.event.id = uuid();
        this.event.created_by = await User.query.get(this.userService.getUserId());
        this.event.created_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        this.event.updated_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      }

      await this.projectService.saveEvent(this.event, this.project);

      this.onCloseClick();
    } else {
      this.showErrors = true;
    }
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/client(popup:admin/project/client/events)');
  }

  private updateForm() {
    this.form.reset({
      id: this.event.id,
      event: this.event.event || '',
    });
  }
}
