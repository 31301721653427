import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  constructor(private route: ActivatedRoute, private router: Router) {
  }

  public retry(): void {
    this.router.navigate([this.route.snapshot.queryParamMap.get('redirectUrl')]);
  }
}
