import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineTableOptions } from '@shared/controls/table/online-table.component';

@Component({
  selector: 'app-options-list',
  templateUrl: './options-list.component.html',
  styleUrls: ['./options-list.component.scss']
})
export class ManageOptionsListComponent implements OnInit {
  public tableOptions: OnlineTableOptions;

  public constructor(private router: Router) {
  }

  public async ngOnInit(): Promise<void> {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Volgorde', name: 'index', filter: { global: false } },
        { title: 'Naam', name: 'name', filter: { global: true } },
        { title: 'Groepen', name: 'relation_group_name', filter: { global: false } },
        { title: 'Omschrijving', name: 'description_available', filter: { global: false } },
        { title: 'Prijs excl.', name: 'cost_rate', filter: { global: false } },
        { title: 'BTW %', name: 'vat_rate', filter: { global: false } },
        { title: 'Notificatie', name: 'sends_notification_label', filter: { global: false } },
        { title: 'St. Ges.', name: 'is_default_selected' },
      ],
      endpoint: '/options',
      search: true,
      withDelete: true,
      withFilter: false,
      sort: { column: 'index' },
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          if (row.relation_groups && row.relation_groups.length > 0) {
            for (const relationGroup of row.relation_groups) {
              row.relation_group_name = row.relation_group_name ? (row.relation_group_name + ', ' + relationGroup.name) : relationGroup.name;
            }
          }

          row.sends_notification_label = this.convertToYesNoLabel(row.sends_notification);
          row.description_available = this.convertToYesNoLabel(row.description);
          row.is_default_selected = this.convertToYesNoLabel(row.is_default_selected);
        }

        return rows;
      },
    });
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`/admin/manage/options(popup:admin/manage/options/${row.id})`);
  }

  public addNewItem(): void {
    this.router.navigateByUrl('/admin/manage/options(popup:admin/manage/options/add)');
  }

  private convertToYesNoLabel(value: boolean): string {
    return value ? 'Ja' : 'Nee';
  }
}
