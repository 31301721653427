import { DomainModel } from '@domain/domain.model';

export class RelationGroup extends DomainModel {

  // Configuration
  public entity = 'relation-group';
  public table = 'relation_groups';
  public sync = true;

  // Fields
  public name: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
