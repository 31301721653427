import { Component, OnInit } from '@angular/core';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {
  public tenantName: string;

  constructor(public userService: UserService) {
    this.tenantName = '';
  }

  public ngOnInit(): void {
    this.tenantName = this.userService.hasRole('superuser') ? 'entities.tenants' : 'entities.locations';
  }
}
