import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-address-fields-list',
  templateUrl: './address-fields-list.component.html',
  styleUrls: ['./address-fields-list.component.scss']
})
export class ManageAddressFieldsListComponent implements OnInit {
  public tableOptions: OnlineTableOptions;

  public constructor(private router: Router) {}

  public async ngOnInit(): Promise<void> {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Naam', name: 'name', filter: { global: true } },
        { title: 'Soort', name: 'type', filter: { global: false } },
        { title: 'Verplicht?', name: 'required', filter: { global: false } },
      ],
      endpoint: '/address-fields',
      search: true,
      withDelete: true,
      withFilter: false,
      sort: { column: 'name' },
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          if (row.type && row.type === 'string') {
            row.type = 'Tekst';
          } else if (row.type && row.type === 'boolean') {
            row.type = 'Checkbox';
          }

          row.required = !!row.required ? 'Ja' : 'Nee';
        }

        return rows;
      },
    });
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`/admin/manage/address-fields(popup:admin/manage/address-fields/${row.id})`);
  }

  public addNewItem(): void {
    this.router.navigateByUrl('/admin/manage/address-fields(popup:admin/manage/address-fields/add)');
  }
}
