import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as toastr from 'toastr';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from '@root/node_modules/rxjs';
import { DataService } from '@shared/services/data.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { DefaultInventory } from '@domain/models/default-inventory.model';
import { takeUntil } from '@node_modules/rxjs/operators';

@Component({
  selector: 'app-default-inventories-detail',
  templateUrl: './default-inventories-detail.component.html',
  styleUrls: ['./default-inventories-detail.component.scss']
})
export class ManageDefaultInventoriesDetailComponent implements OnInit, OnDestroy {
  public isAdd = false;
  public loading = false;
  public showErrors = false;
  public form: FormGroup;
  public mode = { isAdd: true };
  public defaultInventory: DefaultInventory = new DefaultInventory({}).getData();
  public defaultInventoryTypes: SelectItem[];
  public defaultItemsList: SelectItem[] = [];
  public selectedDefaultItems: number[] = [];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
              private dataService: DataService,
              private api: ApiServiceWithLoaderService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private synchronisationService: SynchronisationService,
              private confirmationService: ConfirmationService,
              private route: ActivatedRoute) {
    this.form = new FormGroup({});
    this.defaultInventoryTypes = [
      {
        label: 'Particulier',
        value: 'private',
      },
      {
        label: 'Zakelijk',
        value: 'business',
      },
    ];
    this.isAdd = false;
    this.loading = true;
    this.showErrors = false;

    // Get id of url to edit by route params
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      const id = params['id'];
      this.isAdd = id === 'add';

      this.api.get('/default-items/list').pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result && result.data) {
          this.defaultItemsList = result.data;
        }
      });

      if (!this.isAdd) {
        this.api.get('/default-inventories/' + id).pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
          if (result && result.data) {
            this.defaultInventory = result.data;
            this.defaultInventory.type_label = result.data.type === 'private' ? 'Particulier' : 'Zakelijk';
            this.selectedDefaultItems = result.data.default_items.map((item) => (item.id));
          }

          this.initForm();
          this.loading = false;
        });
      } else {
        this.initForm();
        this.loading = false;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.initForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      type: [ this.defaultInventory.type || 'private' , [Validators.required]],
      name: [ this.defaultInventory.name || '' , [Validators.required]],
      auto_generate: [ this.defaultInventory.auto_generate || '' , [Validators.required]],
      default_items: [ this.selectedDefaultItems || [] , [Validators.required]],
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      const itemData = this.form.getRawValue();

      this.showErrors = false;
      this.loading = true;

      let request;
      if (this.isAdd) {
        request = this.api.post('/default-inventories', itemData);
      } else {
        request = this.api.patch('/default-inventories/' + this.route.snapshot.params['id'], itemData);
      }

      request.subscribe(_ => {
        toastr.success('Ruimte succesvol opgeslagen', 'Standaard ruimtes');
        this.router.navigateByUrl('/').then(() => {
          // HACK Use double navigation to force reload..
          this.router.navigateByUrl('/admin/manage/default-inventories');
        });
      }, (error: any) => {
        if (error.status === 422 && error.json) {
          toastr.warning('Ongeldige invoer', 'Standaard ruimtes');
        } else {
          toastr.error('Fout bij opslaan ruimte', 'Standaard ruimtes');
        }

        this.loading = false;
      });
    } else {
      this.showErrors = true;
    }
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/manage/default-inventories');
  }
}
