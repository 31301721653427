import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from '@node_modules/rxjs';
import { from } from 'rxjs';
import { QuotationCheck } from '@domain/models/quotation-check.model';
import { catchError, map, takeUntil } from '@node_modules/rxjs/operators';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { QuotationCheckService } from '@features/manage/quotation-checks/services/quotation-check.service';

@Component({
  selector: 'app-quotation-checks',
  templateUrl: './quotation-checks.component.html',
  styleUrls: ['./quotation-checks.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuotationChecksComponent),
      multi: true
    }
  ]
})
export class QuotationChecksComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  public projectType: string;
  public quotationChecks$: Observable<QuotationCheck[]>
  public formGroup: FormGroup = new FormGroup({});
  private destroy$ = new Subject<boolean>();
  protected value: { id: string, checked: boolean }[] = [];

  constructor(
      private formBuilder: FormBuilder,
      private quotationCheckService: QuotationCheckService,
  ) {
  }

  public get checkFormArray(): FormArray {
    return this.formGroup.get('checks') as FormArray;
  }

  public onChange: any = () => {
  }

  public onTouch: any = () => {
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(value: { id: string, checked: boolean }[]): void {
    this.value = value;
    if (this.checkFormArray) {
      this.checkFormArray.setValue(value);
    }
  }

  public ngOnInit(): void {
    this.quotationChecks$ = this.getQuotationChecks(this.projectType).pipe(
        tap((quotationChecks) => {
              this.formGroup = this.getFormArray(quotationChecks);
              this.onChange(this.checkFormArray.value);
              this.onTouch(this.checkFormArray.value);
              this.registerValueChanges();
            }
        )
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public allCheckboxesChecked(): boolean {
    return this.checkFormArray && this.checkFormArray.value.every(Boolean);
  }

  private getFormArray(quotationChecks: QuotationCheck[]): FormGroup {
    return this.formBuilder.group({
      checks: this.formBuilder.array(
          quotationChecks.map((quotationCheck) => {
            return this.formBuilder.group({
              id: [quotationCheck.id],
              checked: this.value ? [this.value.some(value => value.checked === true && value.id === quotationCheck.id)] : [],
            });
          })
      ),
    })
  }

  private getQuotationChecks(projectType: string): Observable<QuotationCheck[]> {

    return this.quotationCheckService.indexByType(projectType).pipe(
        catchError(() => {
          return from(QuotationCheck.query.where({ project_type: projectType }).toArray());
        }),
        map((quotationChecks) => {
          return quotationChecks.sort((a, b) => a.index > b.index ? 1 : -1)
        }),
    )
  }

  private registerValueChanges(): void {
    this.checkFormArray.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.onChange(val);
      this.onTouch(val);
    })
  }
}
